export const SET_PUBLICATIONS = "SET_PUBLICATIONS";
export const GET_PUBLICATIONS = "GET_PUBLICATIONS";

export function getPublications(articleId: string | undefined) {
  return {
    type: GET_PUBLICATIONS,
    payload: articleId,
  };
}

export function setPublications(payload: any) {
  return {
    type: SET_PUBLICATIONS,
    payload,
  };
}

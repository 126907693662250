import { IFolder } from "../types/appTypes";
import BlogConnection from "./Connection";
import { executeService2 } from "./FetchService";

export function getAllFolders(): Promise<IFolder[]> {
  const url = new BlogConnection().getURL() + "/folders";
  return executeService2(url);
}

export function getFolderById(id: string): Promise<IFolder> {
  const url = new BlogConnection().getURL() + "/folders/" + id;
  return executeService2(url);
}

export function createFolder(title: string, parentFolderId?: string) {
  const url = new BlogConnection().getURL() + "/folders";
  const capitalizedTitle =
    title.substring(0, 1).toUpperCase() + title.substring(1);
  return executeService2(url, {
    method: "POST",
    payload: { title: capitalizedTitle, folderId: parentFolderId },
    respCode: 201,
    respType: "restrict",
  });
}

export function getContents(id: string) {
  const url = new BlogConnection().getURL() + "/folders/" + id + "/contents";
  return executeService2(url);
}

export function updateFoldername(id: string, title: string) {
  const url = new BlogConnection().getURL() + "/folders/" + id;
  return executeService2(url, { method: "PUT", payload: { title } });
}

export function deleteFolder(id: string) {
  const url = new BlogConnection().getURL() + "/folders/" + id;
  return executeService2(url, {
    method: "DELETE",
    respCode: 204,
    respType: "restrict",
  });
}

import { IFolder } from "../types/appTypes";

export const GET_FOLDERS = "GET_FOLDERS";
export const SET_FOLDERS = "SET_FOLDERS";

export function getFolders() {
  return {
    type: GET_FOLDERS,
  };
}

export function setFolders(payload: IFolder[]) {
  return {
    type: SET_FOLDERS,
    payload,
  };
}

import { ACTION_REFRESH_USER, SET_PROGRESS, RESET } from "../actions/appAction";
import { SET_PUBLICATIONS } from "../actions/publicationsAction";
import { SET_SUBSCRIPTION } from "../actions/commonAction";
import { SET_FOLDERS } from "../actions/foldersAction";

import { IAppReducer } from "../types/reducerTypes";

const initState = {
  folders: [],
};

export default function app(state: any = initState, action: any): IAppReducer {
  switch (action.type) {
    case ACTION_REFRESH_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_PROGRESS:
      return {
        ...state,
        isProgress: action.payload,
      };
    case RESET:
      return {
        ...state,
        user: undefined,
        assets: [],
        limit: undefined,
      };
    case SET_PUBLICATIONS:
      return {
        ...state,
        selectedArticle: {
          ...state.selectedArticle,
          publications: action.payload,
        },
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        limit: action.payload,
      };
    case SET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    default:
      return state;
  }
}

import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHostURL } from "../helpers/URLHelper";
import { getPublished } from "../services/PublicationService";
import { IPublication } from "../types/appTypes";
import FooterComponent from "./FooterComponent";
import LeftPaneMobileComponent from "./LeftPaneMobileComponent";
import LogoWithBackgroundComponent from "./LogoMobileComponent";
import SpinnerComponent from "./shared/SpinnerComponent";
import PublicationsBodyViewComponent2 from "./PublicationsBodyViewComponent2";

interface IProps {
  isMobile: boolean;
}

/**
 * Used by public users.
 * This uses /published2 route to get public article content.
 */
export default function PublicationsPublicViewComponent(props: IProps) {
  const [publication, setPublication] = useState<IPublication>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showOptionsInMobileView, setShowOptionsInMobileView] =
    useState<boolean>(false);
  const { id: articleId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getPublished(articleId)
      .then((publication) => setPublication(publication))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [articleId]);

  return (
    <>
      {props.isMobile && (
        <LogoWithBackgroundComponent
          isMobile={props.isMobile}
          showOptions
          onToggleOptions={() =>
            setShowOptionsInMobileView(!showOptionsInMobileView)
          }
        />
      )}

      {isLoading && <SpinnerComponent />}

      {
        <Drawer
          anchor={"left"}
          open={showOptionsInMobileView}
          sx={{ width: 20 }}
          onClose={() => setShowOptionsInMobileView(false)}
        >
          <LeftPaneMobileComponent />
        </Drawer>
      }

      {!isLoading && (
        <PublicationsBodyViewComponent2
          onCopyLink={() => {
            window.navigator.clipboard.writeText(
              `${getHostURL()}/articles/${articleId}/published`,
            );
          }}
          isMobile={props.isMobile}
          showHome
          publication={publication}
        />
      )}

      {props.isMobile && <FooterComponent />}
    </>
  );
}

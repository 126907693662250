import React from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { setUser } from "./actions/appAction";
import LeftPaneComponent from "./components/LeftPaneComponent";
import NotFoundComponent from "./components/NotFoundComponent";
import PublicationsPublicViewComponent from "./components/PublicationsPublicViewComponent";
import PublicationsRawViewComponent from "./components/PublicationsRawViewComponent";
import AssetsComponent2 from "./components/assets/AssetsComponent2";
import EditArticleComponentNew from "./components/edit/EditArticleComponentNew";
import SecuredRoutes from "./components/higher-order/SecuredRoutes";
import HomeComponent from "./components/home/HomeComponent";
import AllPublicPublicationsComponent from "./components/public/AllPublicPublicationsComponent";
import { session } from "./services/AuthService";
import store from "./store";
import { IUser } from "./types/appTypes";

interface IProps {
  user?: IUser;
}

const App = ({ user }: IProps) => {
  const userAgent = window.navigator.userAgent;
  const isMobile: boolean =
    !!userAgent &&
    (userAgent.includes("Android") || userAgent.includes("iPhone"));

  let location = useLocation();
  const isRawView = new RegExp(/\/body$/).test(location.pathname);
  const isDesktop = !isMobile;
  // Simple - show only in desktop mode and non-raw mode
  const showLeftPanel = isDesktop && !isRawView;

  React.useEffect(() => {
    session()
      .then((result) => store.dispatch(setUser(result)))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const securedRoutes = (
    <SecuredRoutes user={user} isMobile={isMobile}>
      <Routes>
        <Route
          path="/articles/:id/published"
          element={<PublicationsPublicViewComponent isMobile={isMobile} />}
        />
        <Route
          path="/articles/:id/edit"
          element={<EditArticleComponentNew />}
        />
        <Route path="/assets/*" element={<AssetsComponent2 />} />
        <Route path="/home" element={<HomeComponent />} />
        <Route path="/" element={<HomeComponent />} />
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </SecuredRoutes>
  );

  return (
    <div style={{ display: "flex" }} id="pageContent">
      {showLeftPanel && (
        <div className="splitleft">
          <LeftPaneComponent />
        </div>
      )}

      <div className={isMobile ? "spreadfull" : "splitright"}>
        <Routes>
          <Route
            path="/articles/:id/published/body"
            element={<PublicationsRawViewComponent isMobile={isMobile} />}
          />
          <Route
            path="/articles/:id/published"
            element={<PublicationsPublicViewComponent isMobile={isMobile} />}
          />

          <Route
            path="/feeds"
            element={<AllPublicPublicationsComponent isMobile={isMobile} />}
          />
          <Route path="*" element={securedRoutes} />
        </Routes>
      </div>
    </div>
  );
};

function mapStateToProps(state: any): IProps {
  const { user } = state.app;
  return { user };
}
export default connect(mapStateToProps)(App);

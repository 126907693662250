import React, { useEffect, useState } from "react";
import { getPublished } from "../services/PublicationService";
import { IPublication } from "../types/appTypes";
import SpinnerComponent from "./shared/SpinnerComponent";
import { useLocation } from "react-router-dom";
import PublicationsBodyViewComponent2 from "./PublicationsBodyViewComponent2";

interface IProps {
  isMobile: boolean;
}

interface IState {
  publication?: IPublication;
  isLoading: boolean;
}

/**
 * Accessed via /published/body route.
 * This component does not have a Blogs header.
 */
export default function PublicationsRawViewComponent(
  { isMobile }: IProps,
  state: IState,
) {
  const [publication, setPublication] = useState<IPublication>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const noHeader = queryParams.get("no_header");

  useEffect(() => {
    setIsLoading(true);
    const path = location.pathname;
    const articleProp = path?.substring(
      path?.indexOf("articles/"),
      path?.indexOf("/published"),
    );
    const articleId = articleProp.replace("articles/", "");

    getPublished(articleId)
      .then((pub) => setPublication(pub))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [location.pathname]);

  if (!isLoading) {
    return (
      <PublicationsBodyViewComponent2
        isMobile={isMobile}
        publication={publication}
        noHeader={!!noHeader}
      />
    );
  }

  return <SpinnerComponent />;
}

import { Button, TextField, Tooltip } from "@mui/material";
import React from "react";

interface IProps {
  onUpdateHandler: (articleName: string) => void;
  textid: string;
  spanid: string;
  defaultValue: string;
  size?: string;
  maxWidth?: string;
}
interface IState {
  editMode: boolean;
  editText: string;
}

export default class EditableTextLabelComponent extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editMode: false,
      editText: this.props.defaultValue,
    };
    this._handleCancelEdit = this._handleCancelEdit.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ editText: this.props.defaultValue });
    }
  }

  _handleCancelEdit(e: any) {
    const idAttr = e.target.getAttribute("id");
    if (
      this.state.editMode &&
      idAttr !== this.props.spanid &&
      idAttr !== this.props.textid
    ) {
      this.setState({ editMode: false });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this._handleCancelEdit);
  }

  componentWillUnmount() {
    document.addEventListener("click", this._handleCancelEdit);
  }

  renderComponent() {
    if (this.state.editMode) {
      return (
        <>
          <TextField
            error={this.state.editText.trim().length > 0 ? undefined : true}
            id={this.props.textid}
            label="Article Name"
            defaultValue={this.state.editText}
            variant="standard"
            InputProps={{
              style: { fontSize: this.props.size === "small" ? 15 : 30 },
            }}
            onChange={(e) => this.setState({ editText: e.target.value })}
            fullWidth={true}
          />

          <Button
            id="save-editable-text"
            onClick={(e: any) =>
              this.props.onUpdateHandler(this.state.editText)
            }
          >
            Save
          </Button>
        </>
      );
    } else {
      return (
        <Tooltip title="Click to Edit">
          <span
            className={
              this.props.size === "small" ? "clickable-small" : "clickable"
            }
            id={this.props.spanid}
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: this.props.maxWidth ?? 200,
            }}
            onClick={() => this.setState({ editMode: true })}
          >
            {this.state.editText}
          </span>
        </Tooltip>
      );
    }
  }

  render() {
    return this.renderComponent();
  }
}

export const ACTION_LOGOUT_USER = "LOGOUT_USER";
export const ACTION_REFRESH_USER = "REFRESH_USER";
export const RESET = "RESET";
export const ACTION_DO_REFRESH_USER = "ACTION_DO_REFRESH_USER";
export const SET_PROGRESS = "SET_PROGRESS"; // used to show loading icon in home page since we use sagas and they are slow.
export const SET_MODE = "SET_MODE";
export const SET_SETTLE = "SET_SETTLE";

export function logoutUser(msg: string) {
  return {
    type: ACTION_LOGOUT_USER,
    payload: msg,
  };
}

export function setUser(payload: any) {
  return {
    type: ACTION_REFRESH_USER,
    payload,
  };
}

// Same as above, but this is associated with saga that refreshes the user behind the scenes.
export function doRefreshUser() {
  return {
    type: ACTION_DO_REFRESH_USER,
  };
}

export function setProgress(payload: boolean) {
  return {
    type: SET_PROGRESS,
    payload,
  };
}

export function resetAction() {
  return {
    type: RESET,
  };
}

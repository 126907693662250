import { ISubscription } from "../types/appTypes";

export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";

export function getSubscription(payload: any) {
  return {
    type: GET_SUBSCRIPTION,
    payload,
  };
}

export function setSubscription(payload: ISubscription) {
  return {
    type: SET_SUBSCRIPTION,
    payload,
  };
}

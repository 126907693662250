import { IArticle, IAsset, IUploadJob } from "../types/appTypes";
import BlogConnection from "./Connection";
import { executeService2, uploadService } from "./FetchService";

interface IAssetUploadParams {
  folderId?: string;
  articleId?: string;
}

export function uploadAsset(files: File | File[], params?: IAssetUploadParams) {
  const { folderId, articleId } = params || {};
  const _files = Array.isArray(files) ? files : [files];
  const queryParams = [];
  if (folderId) {
    queryParams.push(`folderId=${folderId}`);
  }
  if (articleId) {
    queryParams.push(`articleId=${articleId}`);
  }

  const url =
    new BlogConnection().getURL() + "/v2/assets?" + queryParams.join("&");
  const formData = new FormData();
  for (const file of _files) {
    formData.append("assets", file, file.name);
  }
  return uploadService(url, formData, "POST", 200, true);
}

export function getUploadStatus(jobId: string): Promise<IUploadJob> {
  const url = new BlogConnection().getURL() + "/jobs/" + jobId + "/status";
  return executeService2(url);
}

export function getAllAssets(assetIds?: string[]): Promise<IAsset[]> {
  let queryParams = "?";
  if (assetIds) {
    queryParams = queryParams + `&ids=${assetIds.join(",")}`;
  }
  const url = new BlogConnection().getURL() + "/assets" + queryParams;
  return executeService2(url);
}

export function updateAssetName(assetId: string, newfilename: string) {
  const url = `${new BlogConnection().getURL()}/assets/${assetId}?`;
  return executeService2(url, {
    method: "PUT",
    payload: { filename: newfilename },
    respType: "restrict",
  });
}

export function getAsset(id: string): Promise<IAsset> {
  const url = new BlogConnection().getURL() + "/assets/" + id;
  return executeService2(url);
}

export function getAssetThumbnail(id: string): Promise<IAsset> {
  const url = new BlogConnection().getURL() + "/assets/" + id + "/thumbnail";
  return executeService2(url, { respType: "restrict" });
}

export function publishThumbnail(id: string): Promise<IAsset> {
  const url = new BlogConnection().getURL() + "/assets/" + id + "/thumbnail";
  return executeService2(url, { method: "POST", respCode: 201 });
}

export function deleteAsset(id: string, stale?: boolean) {
  const params = stale ? `?stale=true` : "";
  const url = new BlogConnection().getURL() + "/assets/" + id + params;
  return executeService2(url, { method: "DELETE", respCode: 204 });
}

export function moveToFolder(assetId: string, folderId: string) {
  const url = `${new BlogConnection().getURL()}/assets/${assetId}/folders/${folderId}`;
  return executeService2(url, { method: "POST", respType: "restrict" });
}

export function getWhereUsed(assetId: string): Promise<IArticle[]> {
  const url = `${new BlogConnection().getURL()}/assets/${assetId}/whereused`;
  return executeService2(url);
}

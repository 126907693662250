import { Drawer } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";

interface IProps {
  open: boolean;
  title: string;
  subtitle: string;
  onDeleteHandler: () => void;
  onCancel: () => void;
}
export default class DeleteConfirmationDrawerComponent extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Drawer
        anchor={"top"}
        open={this.props.open}
        // onClose={() => {}}
      >
        <div style={{ padding: 40, display: "flex", justifyContent: "center" }}>
          <div className="divColumn">
            <span style={{ fontSize: "larger", fontWeight: "bold" }}>
              {this.props.title}
            </span>

            <span style={{ fontSize: "medium", margin: "10px 0px" }}>
              {this.props.subtitle}
            </span>

            <div className="divCenterAlign" style={{ marginTop: 10 }}>
              <Button
                sx={{ backgroundColor: "gray" }}
                id="delete-confirm-no"
                onClick={this.props.onCancel}
                variant="contained"
                size="small"
              >
                No
              </Button>

              <Button
                sx={{ margin: "0px 20px" }}
                id="delete-confirm-yes"
                onClick={this.props.onDeleteHandler}
                variant="contained"
                size="small"
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}

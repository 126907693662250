import { call, put, takeEvery } from "redux-saga/effects";
import { GET_SUBSCRIPTION, setSubscription } from "../actions/commonAction";
import * as Api from "../services/CommonService";
import { ISubscription } from "../types/appTypes";

function* fetchSubscription(action: any) {
  try {
    const subscription: ISubscription = yield call(
      Api.getSubscription,
      action.payload.username,
    );
    yield put(setSubscription(subscription));
  } catch (e) {
    console.error(e);
  }
}

export default function* subscriptionsSaga() {
  yield takeEvery(GET_SUBSCRIPTION, fetchSubscription);
}

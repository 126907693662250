import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React from 'react';
import { getFeeds } from '../../services/FeedsService';
import LogoWithBackgroundComponent from '../LogoMobileComponent';
import Button from '@mui/material/Button';
import { Divider, List } from '@mui/material';
import { Link } from 'react-router-dom';
import { IArticle } from '../../types/appTypes';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';

interface IProps {
  isMobile: boolean;
}
interface IState {
  publicArticles: IArticle[];
  filteredArticles: IArticle[];
  searchFilter: string;
}

export default class AllPublicPublicationsComponent extends React.Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      publicArticles: [],
      filteredArticles: [],
      searchFilter: ""
    };
  }


  async componentDidMount() {

      try {
        const articles = await getFeeds(100);
        this.setState({publicArticles: articles, filteredArticles: articles});
      } catch (err: any) {
        console.error(err);
      }
   
  }

  filterArticles() {
    const originalArticles = [...this.state.publicArticles];

    const filteredArticles = this.state.searchFilter.trim().length > 0 ?
      originalArticles.filter( (article: IArticle) => article.title.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
      article.publications?.[0].user?.firstname.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
        article.publications?.[0].user?.lastname.toLowerCase().includes(this.state.searchFilter.toLowerCase()) 
        )
        : originalArticles;

    this.setState({filteredArticles});
  }



  render() {
    return (
      <div className="margin-center">


        {
              this.props.isMobile &&
                <LogoWithBackgroundComponent/>
        }

        <div className="divCenterAlign" style={{margin: '3em 0em'}}>
          <Typography
            sx={{display: 'flex', textAlign: 'center'}}
            component="span"
            variant="h4"
            color="text.primary"
          >
            Freedom of knowledge and speech starts here!
          </Typography>
        </div>


        <div className={this.props.isMobile ? "divCenterAlign divColumn mx-10" : 'divSpread'}>

          <div className='my-10'>
          <Link to="/">Back to Home</Link>
          </div>

          <div>
            {
              this.state.searchFilter.trim().length > 0 &&
              <Button onClick={() => this.setState({searchFilter: ""}, this.filterArticles)} 
                color="error" id="clear" size="large">Clear</Button>
            }

            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by title or user..."
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(e) => this.setState({searchFilter: e.target.value}, this.filterArticles)}
                value={this.state.searchFilter}
              />
              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            
            </Paper>

          </div>

         
        </div>

  
        <div className="divFlex" style={{marginTop: '44px'}}>

         
      <List sx={{ width: '100%', marginBottom: 3, display: this.state.publicArticles?.length > 0 ? "visible" : "none"}}>
      {
        this.state.filteredArticles && this.state.filteredArticles.map ( (article: IArticle, index: number) => {
          return (
              <Link to={`/articles/${article._id}/published?q=${article.title?.trim().split(" ").join("+")}`} className="hyperlink-no-link" key={index}>
              <ListItem alignItems="flex-start" sx={{cursor: "pointer", color: 'black'}} >
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp">{article.publications?.[0].user?.firstname?.substring(0, 1) ?? "?"} </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary= { 
                    <Typography
                        component="span"
                        variant="h5"
                      >
                          {article.title}
                        </Typography>
                    }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="strong"
                        variant="subtitle1"
                        color="var(--exhut-primary)"
                      >
                        {`${article.publications[0].user?.lastname}, ${article.publications[0].user?.firstname} `}
                      </Typography>

                      <span className='infoMsg'>
                      - {new Date(article.publications[article.publications.length - 1].publishedDate).toDateString()}
                      </span>
                    </React.Fragment>
                  }
                />
                <strong>{article.publications.length > 0 ? article.publications[article.publications.length - 1].views?.length : 0} views</strong>
              </ListItem>
              <Divider variant="inset" component="li" />
            </Link>
            
          

          
          )
        })
      }
      </List>

        

        </div>

      
      </div>
    );
  }
}

import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitles: string[];
  onProceed: () => void;
  successBtnClass?: string;
  successBtnLabel?: string;
  failureBtnLabel?: string;
  hideWarnIcon?: boolean;
}

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  subtitles,
  onProceed,
  hideWarnIcon,
  successBtnLabel,
  failureBtnLabel,
}: IProps) {
  return (
    <Dialog open={open} onClose={onClose} id="confirm-dialog">
      <div className="p-40">
        <DialogTitle>
          <div className="divAlignItemsOnly">
            {!hideWarnIcon && (
              <img
                src={"/warning.png"}
                className="iconSizeLarge mx-10"
                alt="warning"
              />
            )}

            <h3>{title} </h3>
          </div>
        </DialogTitle>

        {subtitles.map((subtitle) => (
          <DialogContent>
            <p style={{ margin: "0px 20px" }}>{subtitle}</p>
          </DialogContent>
        ))}

        <DialogActions>
          <Button onClick={onClose}>{failureBtnLabel ?? "No"}</Button>
          <Button variant="contained" onClick={onProceed}>
            {successBtnLabel ?? "Yes"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

import parser from "node-html-parser";

export function parseStringToEditorJsBlock(htmlString: string) {
  const root = parser.parse(htmlString);
  const textValues = findNodesByDepthFirstSearch(root, []);

  const blocks: any[] = [];
  textValues.forEach((text, index) => {
    blocks.push({
      type: "paragraph",
      data: {
        text,
      },
      id: (Date.now() + index).toString(),
    });
  });

  return blocks;
}

export function findNodesByDepthFirstSearch(node: any, arr: string[]) {
  // 3 means text node. And anchor tags have child nodes length 1
  if (node.nodeType === 1 && node.rawTagName === "img") {
    console.log("node is ", node.getAttribute("id"));
  }
  if (
    (node.nodeType === 3 && node.rawText.trim() !== "") ||
    node.childNodes.length === 1
  ) {
    if (!node.rawText.trim().includes("<!DOCTYPE")) {
      arr.push(node.rawText.replace("\n", "").trim());
    }
    return arr;
  }

  node.childNodes.forEach((n: any) => findNodesByDepthFirstSearch(n, arr));
  return arr;
}

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { createArticle } from "../../services/ArticleService";
import "./HomeComponent.css";

interface IProps {
  onSuccess: () => void;
  onBack: () => void;
}

export default function CreateArticleComponent({ onSuccess, onBack }: IProps) {
  const [isProgress, setIsProgress] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      articleName: "",
    },
    validationSchema: Yup.object({
      articleName: Yup.string()
        .min(3, "Article name should be 3 or more chars")
        .required("Please give a name to article"),
    }),
    onSubmit: async (values) => {
      setIsProgress(true);
      createArticle(values.articleName)
        .then(onSuccess)
        .catch(() => {})
        .finally(() => setIsProgress(false));
    },
  });

  return (
    <div className="divFlex" style={{ margin: "0px 10px" }}>
      <form>
        <TextField
          helperText={formik.errors.articleName}
          error={!!formik.errors.articleName}
          sx={{ width: "20vw", margin: "0px 40px 0px 0px" }}
          inputProps={
            {
              maxLength: 45,
              style: {
                fontSize: "20px",
              },
            } as any
          }
          id="articleName"
          placeholder="Article name.."
          {...formik.getFieldProps("articleName")}
          variant="standard"
        />
        <input
          type="submit"
          disabled={Object.keys(formik.errors).length > 0 || isProgress}
          id="create-article"
          value={"Add"}
          className="bigButtonGreen"
          onClick={formik.submitForm}
        />

        <Button id="cancel-create-article" onClick={onBack} size="large">
          Cancel
        </Button>
      </form>
    </div>
  );
}

import { Drawer } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getPublications } from "../../actions/publicationsAction";
import { getDeleteIcon } from "../../helpers/IconHelper";
import { deletePublication } from "../../services/PublicationService";
import store from "../../store";
import moment from "moment";

interface IProps {
  articleId?: string;
  open: boolean;
  onClose: () => void;
  publications: any[];
}

interface IState {}
class AllPublicationsComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      publications: [],
    };
    this.deletePublicationHandler = this.deletePublicationHandler.bind(this);
  }

  async componentDidMount() {
    store.dispatch(getPublications(this.props.articleId));
  }

  async componentDidUpdate(prevProps: IProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      store.dispatch(getPublications(this.props.articleId));
    }
  }

  async deletePublicationHandler(id: string) {
    try {
      if (this.props.articleId) {
        await deletePublication(this.props.articleId, id);
        store.dispatch(getPublications(this.props.articleId));
        store.dispatch(getPublications(this.props.articleId));
      }
    } catch (err: any) {
      console.error(err);
    }
  }

  render() {
    if (this.props.open) {
      return (
        <Drawer
          anchor={"right"}
          open={true}
          onClose={this.props.onClose}
          id="all-publication-component"
        >
          <div>
            <h3 className="divCenterAlign"> All Publications </h3>
            <ul style={{ padding: 20, lineHeight: 4, minWidth: 300 }}>
              {this.props.publications &&
                this.props.publications.map((pub, i) => {
                  return (
                    <li key={i}>
                      <div className="divSpread">
                        <div style={{ width: 15 }}>{i + 1}.</div>

                        <div style={{ width: 30 }} className="boldText">
                          V{this.props.publications.length - i}
                        </div>

                        <div style={{ width: 200 }}>
                          {moment(pub.publishedDate).format(
                            "DD MMM YYYY hh:mm A",
                          )}{" "}
                        </div>

                        <div style={{ width: 30 }} className="divCenterAlign">
                          {0 === i && (
                            <img
                              src={getDeleteIcon()}
                              width={30}
                              alt="delete"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.deletePublicationHandler(pub._id)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </Drawer>
      );
    }
    return null;
  }
}

function mapStateToProps(state: any) {
  const { publications } = state.app?.selectedArticle ?? {};
  return { publications };
}

export default connect(mapStateToProps)(AllPublicationsComponent);

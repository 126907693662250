import { Drawer, ImageList } from "@mui/material";
import React from "react";
import { getContents, getFolderById } from "../../services/FoldersService";
import { ICurrentItem, IFolderData } from "../../types/appTypes";
import TileComponent from "./TileComponent";
import StatusMessageComponent, {
  IStatusMsg,
} from "../shared/StatusMessageComponent";
interface IProps {
  articleId?: string;
  open: boolean;
  onClose: () => void;
  onAddAsset: (item: IFolderData) => void;
}
interface IState {
  folderData: IFolderData[];
  currentItem?: ICurrentItem;
  folderName: string;
  statusMsg: IStatusMsg | undefined | null;
}
export default class AllAssetsEditorComponent extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      folderData: [],
      folderName: "Home",
      statusMsg: undefined,
    };
  }

  async fetchFolderAndContents(id?: string) {
    if (!id) {
      id = "root";
    }
    if (
      this.state.currentItem?.type === "folder" &&
      this.state.currentItem?.id
    ) {
      const folder = await getFolderById(this.state.currentItem.id);
      this.setState({ folderName: folder.name });
    } else {
      this.setState({ folderName: "Home" });
    }
    const folderData = await getContents(id);
    this.setState({ folderData });
  }

  async componentDidMount() {
    try {
      await this.fetchFolderAndContents(this.state.currentItem?.id);
    } catch (err) {
      console.error(err);
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! There was a problem fetching data. Please try again.",
        },
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (this.state.currentItem !== prevState.currentItem) {
      this.fetchFolderAndContents(this.state.currentItem?.id);
    }
  }

  render() {
    if (this.props.open) {
      return (
        <Drawer anchor={"right"} open={true} onClose={this.props.onClose}>
          <div id="all-assets-editor-component" style={{ padding: 20 }}>
            <h3 className="divCenterAlign"> {this.state.folderName} </h3>

            {!!this.state.currentItem?.id && (
              <div className="my-10">
                <span
                  className="hyperlink"
                  onClick={() =>
                    this.setState({
                      currentItem: {
                        id: this.state.currentItem?.folderId,
                        type: "folder" /* it should go to parent folder which is always folder */,
                      },
                    })
                  }
                >
                  Back
                </span>
              </div>
            )}

            <StatusMessageComponent message={this.state.statusMsg} />

            <ImageList sx={{ minWidth: 200 }} cols={1} rowHeight={"auto"}>
              <TileComponent
                size="medium"
                folderData={this.state.folderData}
                onSelect={(data) =>
                  data.type === "folder"
                    ? this.setState({
                        currentItem: {
                          id: data.id,
                          type: data.type,
                          folderId: data.folderId,
                        },
                      })
                    : this.props.onAddAsset(data)
                }
                onAddAsset={this.props.onAddAsset}
              />
            </ImageList>
          </div>
        </Drawer>
      );
    }
    return null;
  }
}

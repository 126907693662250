import CloseIcon from "@mui/icons-material/Close";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import PublicIcon from "@mui/icons-material/Public";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TextField, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteArticle, getAllArticles } from "../../services/ArticleService";
import { getAllUsers } from "../../services/UserService";
import { IArticle, IUser } from "../../types/appTypes";
import UpdateProfileComponent from "../UpdateProfileComponent";
import DeleteConfirmationDrawerComponent from "../shared/DeleteConfirmationDrawerComponent";
import SpinnerComponent from "../shared/SpinnerComponent";
import StatusMessageComponent, {
  IStatusMsg,
} from "../shared/StatusMessageComponent";
import CreateArticleComponent from "./CreateArticleComponent";
import "./HomeComponent.css";
import InstructionsComponent from "./InstructionsComponent";
interface IProps {
  user: IUser;
  isProgress: boolean;
}

enum IMODE {
  CREATE,
  HOME,
}

interface IState {
  statusMsg: IStatusMsg | null;
  articles: IArticle[];
  filteredArticles: IArticle[];
  createArticleEditText: string;
  mode: IMODE;
  deleteArticleId?: string;
  moreBreakdownEntries?: any[];
  openTermsConds: boolean;
  isLoading: boolean;
  articleFilter?: string;
}

class HomeComponent extends React.Component<IProps, IState> {
  users: IUser[];

  constructor(props: any) {
    super(props);
    this.users = [];
    this.state = {
      statusMsg: null,
      moreBreakdownEntries: undefined,
      createArticleEditText: "",
      mode: IMODE.HOME,
      deleteArticleId: undefined,
      articles: [],
      filteredArticles: [],
      openTermsConds: true,
      isLoading: false,
      articleFilter: undefined,
    };
    this.createArticleSuccessHandler =
      this.createArticleSuccessHandler.bind(this);
    this.fetchAllArticles = this.fetchAllArticles.bind(this);
    this.deleteArticleHandler = this.deleteArticleHandler.bind(this);
  }

  async componentDidMount() {
    try {
      await this.fetchAllArticles();
      if (this.props.user.admin === true) {
        this.users = await getAllUsers();
      }
    } catch (err: any) {
      console.error(err);
      this.setState({
        statusMsg: {
          type: "error",
          text: "Some services may not be available at this time. You can continue your work and save your data!",
        },
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (this.state.articleFilter !== prevState.articleFilter) {
      if (
        this.state.articleFilter !== undefined &&
        this.state.articleFilter !== null
      ) {
        if (this.state.articleFilter.trim() === "") {
          this.fetchAllArticles();
        } else {
          const filteredArticles = this.state.articles.filter((a) =>
            a.title
              .toLowerCase()
              .includes(this.state.articleFilter!.toLowerCase()),
          );
          this.setState({ filteredArticles });
        }
      }
    }
  }

  async fetchAllArticles() {
    try {
      this.setState({ isLoading: true });
      const articles = await getAllArticles();
      this.setState({ articles, filteredArticles: articles });
    } catch (err: any) {
      console.error(err);
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! Something went wrong. Please try again.",
        },
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async deleteArticleHandler() {
    try {
      if (this.state.deleteArticleId) {
        await deleteArticle(this.state.deleteArticleId);
        this.setState({
          deleteArticleId: undefined,
          statusMsg: {
            type: "success",
            text: "Article deleted successfully.",
          },
        });
        await this.fetchAllArticles();
      } else {
        this.setState({
          statusMsg: {
            type: "error",
            text: "Hmm.. This is not common. Please try to delete again.",
          },
        });
      }
    } catch (err: any) {
      console.error(err);
    }
  }

  async createArticleSuccessHandler() {
    try {
      this.setState({
        mode: IMODE.HOME,
        createArticleEditText: "",
        statusMsg: {
          type: "success",
          text: "Article created successfully. Click to edit to draft your content.",
        },
      });
      await this.fetchAllArticles();
    } catch (err: any) {
      console.error(err);
      if (err.status === 400) {
        this.setState({
          statusMsg: {
            type: "error",
            text: err.message,
          },
        });
      } else {
        this.setState({
          statusMsg: {
            type: "error",
            text: "Whoops! Something unexpected happened. Please try again later.",
          },
        });
      }
    }
  }

  renderUserDetailsSection(article: IArticle) {
    const user: IUser | undefined = this.users.find(
      (user: IUser) => user.username === article.username,
    );

    if (user) {
      return (
        <>
          Created By
          <strong>{user.lastname + ", " + user.firstname}</strong>
        </>
      );
    }
    return <span>{"User data not found"}</span>;
  }

  renderStatusIcon(article: IArticle) {
    if (article.publications?.length > 0) {
      return (
        <div className="divFlex">
          <Tooltip title={article.visibility ? `Public` : `Private`}>
            <PublicIcon
              sx={{ margin: "0px 5px" }}
              color={article.visibility ? "primary" : undefined}
            />
          </Tooltip>

          <Tooltip
            title={`Publication version ${article.publications.length}.0 available.`}
          >
            <Link
              to={"/articles/" + article._id + "/published"}
              target="_blank"
            >
              <CloudDoneIcon color="primary" />
            </Link>
          </Tooltip>
        </div>
      );
    }
    return (
      <Tooltip title="No publications found.">
        <CloudIcon color="disabled" />
      </Tooltip>
    );
  }

  render() {
    if (!this.props.user.firstname || !this.props.user.lastname) {
      return <UpdateProfileComponent user={this.props.user} />;
    }

    return (
      <div id="home-container">
        <StatusMessageComponent message={this.state.statusMsg} />

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.openTermsConds}
          action={
            <>
              By accessing our website, you must accept the
              <a
                style={{ color: "yellow", marginLeft: 5 }}
                href="https://www.help.expensehut.com/terms.html"
                target={"_blank"}
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              <span style={{ marginLeft: 5, marginRight: 5 }}>and</span>
              <a
                style={{ color: "yellow" }}
                href="https://www.help.expensehut.com/blogs/acceptableUse.html"
                target={"_blank"}
                rel="noreferrer"
              >
                Acceptable Use Policy.
              </a>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={() => this.setState({ openTermsConds: false })}
              >
                {" "}
                <CloseIcon />
              </IconButton>
            </>
          }
        />

        {this.state.isLoading && <SpinnerComponent />}

        {!this.state.isLoading && (
          <div id="home-component">
            <DeleteConfirmationDrawerComponent
              open={!!this.state.deleteArticleId}
              onDeleteHandler={this.deleteArticleHandler}
              onCancel={() => this.setState({ deleteArticleId: undefined })}
              title="Are you sure you want to delete this article?"
              subtitle="This will permanently remove all versions."
            />

            <div className="divSpread">
              <div className="divFlex">
                {this.state.mode !== IMODE.CREATE && (
                  <Button
                    id="create"
                    onClick={() =>
                      this.setState({
                        mode:
                          this.state.mode === IMODE.CREATE
                            ? IMODE.HOME
                            : IMODE.CREATE,
                      })
                    }
                    size="large"
                  >
                    Create
                  </Button>
                )}

                {this.state.mode === IMODE.CREATE && (
                  <CreateArticleComponent
                    onSuccess={this.createArticleSuccessHandler}
                    onBack={() => this.setState({ mode: IMODE.HOME })}
                  />
                )}
              </div>

              {this.state.articles.length > 0 && (
                <div className="divAlignItemsOnly">
                  <Button onClick={this.fetchAllArticles}>
                    <RefreshIcon />
                  </Button>

                  <TextField
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                    onChange={(e) =>
                      this.setState({ articleFilter: e.target.value })
                    }
                  />
                </div>
              )}
            </div>

            <div
              style={{
                float: "left",
                display: "flex",
                overflowY: "scroll",
                flexFlow: "wrap",
              }}
            >
              {this.state.articles && this.state.articles.length === 0 && (
                <InstructionsComponent />
              )}
              {this.state.filteredArticles?.length > 0 &&
                this.state.filteredArticles?.map(
                  (article: IArticle, index: number) => {
                    return (
                      <Card
                        sx={{
                          minWidth: 275,
                          margin: "20px",
                          maxWidth: 275,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        elevation={6}
                        key={index}
                      >
                        <CardContent>
                          <div className="divSpread">
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              Article
                            </Typography>

                            <img
                              src={"./delete.png"}
                              width={25}
                              alt="delete"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({ deleteArticleId: article._id })
                              }
                            />
                          </div>

                          <Link
                            to={
                              article.username === this.props.user.username ||
                              this.props.user.admin === true
                                ? "/articles/" + article._id + "/edit"
                                : ""
                            }
                            style={{
                              color: "var(--exhut-total-green)",
                              fontSize: "15px",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              id="tile-article-title"
                              sx={{ color: "black" }}
                            >
                              {article.title}
                            </Typography>
                            <Typography
                              sx={{ mb: 1.5 }}
                              color="text.secondary"
                              id="tile-article-date"
                            >
                              {new Date(
                                article.createdDate,
                              ).toLocaleDateString()}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ minHeight: "20px" }}
                              id="tile-article-subtitle"
                            >
                              {article.subheading}
                            </Typography>
                          </Link>
                        </CardContent>

                        <div className="divSpread" style={{ padding: 20 }}>
                          {article.username === this.props.user.username && (
                            <>
                              <div className="divCenterAlign">
                                <span
                                  className="infoMsg"
                                  style={{ fontFamily: "cursive" }}
                                >
                                  Views:
                                </span>

                                <strong style={{ margin: "0px 5px" }}>
                                  {" "}
                                  {article.publications
                                    ? article.publications?.[
                                        article.publications.length - 1
                                      ]?.views?.length
                                    : 0}{" "}
                                </strong>
                              </div>
                              {this.renderStatusIcon(article)}
                            </>
                          )}

                          {article.username !== this.props.user.username &&
                            this.renderUserDetailsSection(article)}
                        </div>
                      </Card>
                    );
                  },
                )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { user } = state.app;
  return { user };
}
export default connect(mapStateToProps)(HomeComponent);

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { setUser } from "../../actions/appAction";
import { downloadAndLoadGSIScript } from "../../helpers/ResourceHelper";
import { getHostURLExpenseHut } from "../../helpers/URLHelper";
import * as AppConfigService from "../../services/AppStorageService";
import { authenticate, loginWithSSO } from "../../services/AuthService";
import BlogConnection, { config } from "../../services/Connection";
import store from "../../store";
import LogoWithBackgroundComponent from "../LogoMobileComponent";
import StatusMessageComponent, {
  IStatusMsg,
} from "../shared/StatusMessageComponent";
import "./LoginComponent.css";
import WhatsTrendingComponent from "./WhatsTrendingComponent";

interface IProps {
  isMobile: boolean;
  navigate: any;
}
interface IState {
  errorMsg: IStatusMsg | null;
  email: string;
  password: string;
  isLoginProgress: boolean;
}

export default class LoginComponent extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMsg: null,
      isLoginProgress: false,
    };
    this.loginHandler = this.loginHandler.bind(this);
    this._handleLoginUsingKeyboard = this._handleLoginUsingKeyboard.bind(this);
  }

  async loginSSOHandler(responseFromGmailAuth: any) {
    try {
      // console.log(responseFromGmailAuth);
      this.setState({ isLoginProgress: true });
      // result is user object.
      const result = await loginWithSSO(responseFromGmailAuth.credential);
      if (result.sign) {
        AppConfigService.setSignature(result.sign);
        AppConfigService.setRefreshToken(result.refresh_token);
        this.setState({ isLoginProgress: false });
        this.props.navigate("/home");
        store.dispatch(setUser(result));
      } else {
        throw new Error(
          `Whoops! Something went wrong on our side. Please try after sometime or contact the admin.`,
        );
      }
    } catch (err: any) {
      let message =
        err.message ??
        err.error ??
        "The email or password you have entered is incorrect. Please try again.";
      if (message === "Failed to fetch") {
        message = "Whoops! Our servers appears to be down. Please try later.";
      }
      this.setState({
        errorMsg: { type: "error", text: message },
        password: "",
        isLoginProgress: false,
      });
    }
  }

  async componentDidMount() {
    document.addEventListener("keydown", this._handleLoginUsingKeyboard);

    try {
      await downloadAndLoadGSIScript();
      (window as any).google.accounts.id.initialize({
        client_id: config.googleClientId,
        callback: (response: any) => this.loginSSOHandler(response),
      });
      (window as any).google.accounts.id.renderButton(
        document.querySelector(".sso-signin"),
        { theme: "dark", size: "medium" }, // customization attributes
      );
    } catch (err: any) {
      console.error(err);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleLoginUsingKeyboard);
  }

  _handleLoginUsingKeyboard(event: any) {
    if (event.key === "Enter") {
      this.loginHandler();
    }
  }

  async loginHandler() {
    try {
      this.setState({ isLoginProgress: true });

      const authResult = await authenticate(
        this.state.email,
        this.state.password,
      );
      if (authResult.sign) {
        AppConfigService.setSignature(authResult.sign);
        AppConfigService.setRefreshToken(authResult.refresh_token);
        this.props.navigate("/home");
        store.dispatch(setUser(authResult));
      } else {
        throw new Error(
          `Whoops! Something went wrong on our side. Please try after sometime or contact the admin.`,
        );
      }
    } catch (err: any) {
      console.log(err);
      let message = err.message;
      if (err.status === 401) {
        message = "The email or password entered does not match our records.";
      } else if (message === "Failed to fetch") {
        message = `Whoops! Our servers appears to down at ${new BlogConnection().getURL()}. The admin is working on it.`;
      }
      this.setState({
        errorMsg: {
          type: "error",
          text: message,
        },
      });
    }
    this.setState({ isLoginProgress: false });
    // store.dispatch(setProgress(false));
  }

  render() {
    return (
      <div className="margin-center">
        <StatusMessageComponent
          minHeight="50px"
          message={this.state.errorMsg}
        />

        {this.props.isMobile && <LogoWithBackgroundComponent />}

        <div className="divCenterAlign">
          <Typography
            sx={{ display: "flex", textAlign: "center" }}
            component="span"
            variant="h4"
            color="text.primary"
          >
            Write and publish on the web!
          </Typography>
        </div>

        <div className="divFlex" style={{ marginTop: "44px" }}>
          {!this.props.isMobile && (
            <div className="divColumnCenter" style={{ flex: 1 }}>
              <div className="divColumnCenterAlign">
                <div style={{ margin: "60px 0px 20px 0px" }}>
                  <TextField
                    sx={{ width: "20vw" }}
                    InputProps={{
                      style: {
                        fontSize: "20px",
                      },
                    }}
                    required
                    id="email"
                    placeholder="Email.."
                    defaultValue=""
                    variant="standard"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>

                <div style={{ margin: "30px 0px 10px 0px" }}>
                  <TextField
                    sx={{ width: "20vw" }}
                    InputProps={{
                      style: {
                        fontSize: "20px",
                      },
                    }}
                    id="password"
                    placeholder="Password.."
                    type="password"
                    autoComplete="current-password"
                    variant="standard"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>

                <div
                  style={{ margin: "20px 0px 10px 0px" }}
                  className="divFlex"
                >
                  <Button
                    sx={{ backgroundColor: "var(--exhut-green)", width: "8vw" }}
                    id="login"
                    variant="contained"
                    disabled={this.state.isLoginProgress}
                    onClick={this.loginHandler}
                    size="large"
                  >
                    Login
                  </Button>
                </div>

                <div>
                  <a
                    href={`${getHostURLExpenseHut()}/forgotPassword`}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Forgot Password
                  </a>
                </div>

                <div className="sso-signin" style={{ width: "200px" }}></div>
              </div>
            </div>
          )}

          <WhatsTrendingComponent />
        </div>

        <hr style={{ marginTop: 40 }} />

        <div
          id="login-footer"
          className={this.props.isMobile ? "divColumn" : "divFlex"}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut type-less" src="/clock.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Publish your content in seconds."
              primaryTypographyProps={{ fontSize: "larger" }}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {"Same credentials - multiple apps."}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut type-less" src="/profits.svg" />
            </ListItemAvatar>
            <ListItemText
              primary="Forget paying to read articles."
              primaryTypographyProps={{ fontSize: "larger" }}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {
                      "Blogs are free for both readers and writers. No annoying ads."
                    }
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="exhut type-less" src="/charts.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Don't know web designing? No Problem!"
              primaryTypographyProps={{ fontSize: "larger" }}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {
                      "Use your integrated editor to type and format text content or images in a few clicks."
                    }
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </div>
      </div>
    );
  }
}

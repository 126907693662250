import { AuthConnection } from "./Connection";
import { executeService2 } from "./FetchService";

export function updateProfile(firstName?: string, lastName?: string): any {
  const url = new AuthConnection().getURL() + "/users/profile";
  return executeService2(url, {
    method: "PUT",
    payload: { firstName, lastName },
    respType: "restrict",
  });
}

export function getAllUsers() {
  const url = new AuthConnection().getURL() + "/admin/users";
  return executeService2(url);
}

import { ISubscription } from "../types/appTypes";
import BlogConnection from "./Connection";
import { executeService2 } from "./FetchService";

export function getSubscription(
  username: string,
): Promise<ISubscription> | undefined {
  if (username) {
    const url =
      new BlogConnection().getURL() + "/users/" + username + "/subscription";
    return executeService2(url);
  }
}

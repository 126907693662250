import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import React from "react";

interface IProps {
  tooltip: string;
}

/**
 * Info i component with tooltip.
 *
 * Props:
 *
 * tooltip {string}
 */
export default class InfoComponent extends React.Component<IProps> {
  render() {
    return (
      <Tooltip title={this.props.tooltip}>
        <InfoIcon sx={{ margin: "0px 5px" }} color="info" />
      </Tooltip>
    );
  }
}

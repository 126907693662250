import { Button, Chip, Paper, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  uploadAsset,
  getAllAssets,
  getUploadStatus,
} from "../../services/AssetsService";
import { getSubscription } from "../../services/CommonService";

import { SpinnerWithProgressComponent } from "../shared/SpinnerComponent";
import { ISubscription, IUploadJob, IUser } from "../../types/appTypes";
import moment from "moment";
const FILE_SELECTION_LIMIT = 8;

interface IProps {
  user: IUser;
  onUploadSuccess: () => void;
  folderId?: string;
}

export default function AssetUploadComponent({
  user,
  folderId,
  onUploadSuccess,
}: IProps) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [jobId, setUploadingJobId] = useState<string>();
  const [uploadProgressPercent, setUploadProgressPercent] = useState<number>(0);
  const [fileUploadError, setFileUploadError] = useState<string>();
  const [isUploadProgress, setIsUploadProgress] = useState<boolean>(false);

  const [assetsCount, setAssetsCount] = useState<number>(0);
  const [subscription, setSubscription] = useState<ISubscription>();

  useEffect(() => {
    if (user) {
      getAllAssets().then((assets) => setAssetsCount(assets.length));

      getSubscription(user.username)?.then((subscription: ISubscription) =>
        setSubscription(subscription),
      );
    }
  }, [user]);

  const getJobStatusAsync = useCallback(
    (_jobId?: string) => {
      if (_jobId) {
        getUploadStatus(_jobId)
          .then((job: IUploadJob) => {
            // console.log("Fetched job status: " + job.status, Math.round(job.completed / job.files * 100));
            setUploadProgressPercent(
              Math.round((job.completed / job.files) * 100),
            );
            if (job.status === "COMPLETE") {
              setUploadingJobId(undefined);
              setUploadProgressPercent(0);
              setIsUploadProgress(false);
              onUploadSuccess();
            } else {
              const fiveMins = 5 * 60 * 60; // to millisecs
              const diff = moment().diff(moment(job.lastUpdated));
              if (diff > fiveMins) {
                setFileUploadError(
                  "Whoops! File upload timed out. Please refresh your page.",
                );
              } else {
                setTimeout(() => getJobStatusAsync(_jobId), 1000);
              }
            }
          })
          .catch(() =>
            setFileUploadError("Whoops! Something unexpected again."),
          )
          .finally(() => setIsUploadProgress(false));
      }
    },
    [onUploadSuccess],
  );

  useEffect(() => {
    getJobStatusAsync(jobId);
  }, [jobId, getJobStatusAsync]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    //  2 MB.
    const sizeLimit = 2 * 1000000;
    // indicate if file sizes are bigger than 5MB
    const isSizeExceeded =
      e?.target.files &&
      Array.from(e.target.files).some((f) => f.size > sizeLimit);
    // const totalSize = (e?.target.files ?  Array.from(e?.target?.files).reduce( (acc: number, file: any) => acc + file.size, 0) : 0) / 1000000;

    if (isSizeExceeded) {
      setFileUploadError("One or more files exceeds size limit of 2MB.");
    } else if (
      e?.target.files &&
      e.target.files.length > FILE_SELECTION_LIMIT
    ) {
      setFileUploadError("Please select upto 10 files at a time.");
    } else {
      setSelectedFiles(e?.target.files ? Array.from(e.target.files) : []);
      setFileUploadError(undefined);
    }
  };

  const uploadHandler = async () => {
    try {
      setFileUploadError(undefined);
      setIsUploadProgress(true);
      setSelectedFiles([]);
      if (selectedFiles?.length) {
        const { jobId } = await uploadAsset(selectedFiles, {
          folderId: folderId,
        });
        setUploadingJobId(jobId);
      }
    } catch (err: any) {
      console.error(err);
      let message = "One or more assets failed to upload. Please try again.";
      if (err.status === 413) {
        message = "You cannot exceed file size to be over 4MB.";
      } else if (err.status === 400) {
        try {
          const errorJSON = JSON.parse(err.message);
          message = errorJSON.message;
        } catch (err) {
          console.error(err);
        }
      }
      setFileUploadError(message);
    }
  };

  const handleDeleteSelectedFiles = (index: number) => {
    const files = selectedFiles;
    files.splice(index, 1);
    setSelectedFiles(files);
  };

  return (
    <div className="" id="asset-upload-component">
      {fileUploadError && (
        <span className="divCenter errorMsg">{fileUploadError}</span>
      )}

      <div className="divCenter" style={{ marginTop: 40 }}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <div className="divCenterAlign" style={{ width: "100%" }}>
            {selectedFiles?.length === 0 && (
              <div
                className="divCenterAlign"
                style={{ minWidth: "600px", height: "100px" }}
              >
                {!jobId && (
                  <span className="infoMsg">
                    {`You can select upto ${FILE_SELECTION_LIMIT} files to upload at a time.`}
                  </span>
                )}
                {!!jobId && (
                  <span className="infoMsg">
                    {`Assets upload in progress. Please wait...`}
                  </span>
                )}
              </div>
            )}

            {selectedFiles?.length > 0 && (
              <Stack
                direction="row"
                sx={{
                  width: 600,
                  height: "auto",
                  overflow: "scroll",
                  display: "table-cell",
                }}
                spacing={0}
              >
                {selectedFiles.map((file: File, index: number) => {
                  return (
                    <Chip
                      key={index}
                      label={file.name}
                      onDelete={() => handleDeleteSelectedFiles(index)}
                      sx={{ margin: "5px" }}
                    />
                  );
                })}
              </Stack>
            )}

            <div hidden={isUploadProgress}>
              <Button
                component="label"
                disabled={
                  !!jobId || (subscription?.assets ?? 0) - assetsCount === 0
                }
              >
                Choose File
                <input
                  type="file"
                  hidden
                  multiple={true}
                  id="asset-file-upload-input"
                  accept="image/png, image/jpeg, image/gif"
                  onClick={() => {
                    setSelectedFiles([]);
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onFileChange(e)
                  }
                />
              </Button>
              <Button
                id="asset-upload-btn"
                variant="contained"
                component="label"
                onClick={uploadHandler}
                sx={{ margin: "0px 20px" }}
                disabled={selectedFiles?.length === 0 || isUploadProgress}
              >
                {!!jobId ? "Uploading" : "Upload"}
              </Button>
            </div>
            <div className="p-12">
              {isUploadProgress && (
                <SpinnerWithProgressComponent percent={uploadProgressPercent} />
              )}
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}

const APP_SIGN_KEY = "exhut-blogs-signature";
const APP_REFRESH_TOKEN = "exhut-blogs-refresh_token";
const APP_TERMS_AND_COND = "exhut-blogs-terms";

export function shouldShowTermsConditions() {
  const termsFromLocalStorage = _getFromSessionStorage(APP_TERMS_AND_COND);
  return !termsFromLocalStorage;
}

export function hideShowTermsConditions() {
  _setInSessionStorage(APP_TERMS_AND_COND, "false");
}

export function getSignature() {
  return _getFromLocalStorage(APP_SIGN_KEY);
}

export function getRefreshToken() {
  return _getFromLocalStorage(APP_REFRESH_TOKEN);
}

export function setSignature(value: string | undefined) {
  _setInLocalStorage(APP_SIGN_KEY, value ?? "");
}

export function setRefreshToken(value: string | undefined) {
  _setInLocalStorage(APP_REFRESH_TOKEN, value ?? "");
}

function _getFromLocalStorage(key: string) {
  return localStorage.getItem(key);
}

function _setInLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

function _getFromSessionStorage(key: string) {
  return sessionStorage.getItem(key);
}

function _setInSessionStorage(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

import { AuthConnection } from "./Connection";
import { executeService2 } from "./FetchService";

export function authenticate(email: string, password: string) {
  const url = new AuthConnection().getURL() + "/auth";
  // return executeService2(url, "POST", { email, password }, 200, "json", false);
  return executeService2(url, {
    method: "POST",
    authenticated: false,
    payload: { email, password },
    additionalHeaders: { "access-type": "offline" },
  });
}

export function session() {
  const url = new AuthConnection().getURL() + "/session";
  return executeService2(url, {
    method: "GET",
    authenticated: true,
  });
}

export function logout() {
  const url = new AuthConnection().getURL() + "/logout";
  return executeService2(url, {
    method: "POST",
    respCode: 204,
    respType: "restrict",
  });
}

export function loginWithSSO(gmailToken: string) {
  const url = new AuthConnection().getURL() + "/auth/sso";
  return executeService2(url, {
    payload: { token: gmailToken, type: "google" },
    method: "POST",
    additionalHeaders: { access: "offline" },
    authenticated: false,
  });
}

import { IUser } from "../types/appTypes";

export function getNameLabel(user: IUser | undefined, maxLimit = 10) {
  if (user) {
    const name = user.firstname ?? user.useremail;
    return name.length > maxLimit - 1
      ? name.substring(0, maxLimit - 1) + "..."
      : name;
  }
  return "";
}

import { IPublication } from "../types/appTypes";
import BlogConnection from "./Connection";
import { executeService2 } from "./FetchService";

export function getAllPublications(
  articleId?: string,
): Promise<IPublication[]> {
  const url =
    new BlogConnection().getURL() + "/articles/" + articleId + "/publications";
  return executeService2(url);
}

export function getPublication(
  articleId?: string,
  pubId?: string,
): Promise<IPublication> {
  const url =
    new BlogConnection().getURL() +
    "/articles/" +
    articleId +
    "/publications?pubId=" +
    pubId;
  return executeService2(url);
}

export function getPublished(articleId?: string): Promise<IPublication> {
  const url =
    new BlogConnection().getURL() + "/articles/" + articleId + "/published2";
  return executeService2(url, { authenticated: false });
}

export function createPublication(
  articleId: string,
  bodyContent?: any,
  assets?: (string | null)[],
  version?: string,
) {
  const url =
    new BlogConnection().getURL() + "/articles/" + articleId + "/publications";
  return executeService2(url, {
    method: "POST",
    payload: { bodyContent, assets, version },
    respCode: 201,
    respType: "restrict",
  });
}

export function deletePublication(id: string, pubid: string) {
  const url =
    new BlogConnection().getURL() +
    "/articles/" +
    id +
    "/publications/" +
    pubid;
  return executeService2(url, {
    method: "DELETE",
    respCode: 204,
    respType: "restrict",
  });
}

import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteAsset,
  getAsset,
  getAssetThumbnail,
  getWhereUsed,
  moveToFolder,
  publishThumbnail,
  updateAssetName,
} from "../../services/AssetsService";
import { getAllFolders, getFolderById } from "../../services/FoldersService";

import FolderIcon from "@mui/icons-material/Folder";
import { Button, Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getEmptyIcon } from "../../helpers/IconHelper";
import { IArticle, IAsset, IFolder } from "../../types/appTypes";
import DeleteConfirmationDrawerComponent from "../shared/DeleteConfirmationDrawerComponent";
import EditableTextLabelComponent from "../shared/EditableTextLabelComponent";
import SpinnerComponent from "../shared/SpinnerComponent";
import StatusMessageComponent, {
  IStatusMsg,
} from "../shared/StatusMessageComponent";
interface IProps {
  id: string;
  onBack: () => void;
}

interface IState {
  whereUsed: IArticle[];
  targetFolder: string | undefined;
  folderName: string;
}

export default function AssetFullViewComponent2(
  { id, onBack }: IProps,
  state: IState,
) {
  const [whereUsed, setWhereUsed] = useState<IArticle[]>([]);
  const [targetFolder, setTargetFolder] = useState<string | undefined>();
  const [folderName, setFolderName] = useState<string>("Home");
  const [asset, setAsset] = useState<IAsset>();
  const [folders, setFolders] = useState<IFolder[]>();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [showDeleteConf, setShowDeleteConf] = useState<boolean>(false);
  const [isStale, setIsStale] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isThumbnailAvailable, setIsThumbnailAvailable] =
    useState<boolean>(false);

  const refreshAsset = useCallback(() => {
    if (id) {
      setIsLoading(true);
      getAsset(id)
        .then((asset: IAsset) => {
          setAsset(asset);
          getAssetThumbnail(id)
            .then(() => setIsThumbnailAvailable(true))
            .catch((err) => {
              console.error("Error fetching thumbnail", err);
              setIsThumbnailAvailable(false);
            });
        })
        .catch(() => {
          setAsset(undefined);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    refreshAsset();

    getAllFolders().then((folders: IFolder[]) => setFolders(folders));
  }, [refreshAsset, id]);

  useEffect(() => {
    if (id) {
      getWhereUsed(id).then((articles: IArticle[]) => setWhereUsed(articles));
    }
  }, [id]);

  useEffect(() => {
    if (asset?.folderId) {
      getFolderById(asset?.folderId).then((folder: IFolder) =>
        setFolderName(folder.name),
      );
    }
  }, [asset]);

  const publishThumbnailHandler = useCallback(() => {
    setIsLoading(true);
    publishThumbnail(id)
      .then(() =>
        getAssetThumbnail(id)
          .then(() => {
            setIsLoading(false);
            setIsThumbnailAvailable(true);
          })
          .catch((err) => {
            console.error("Error fetching thumbnail", err);
            setIsThumbnailAvailable(false);
            setIsLoading(false);
          })
          .finally(() => setIsLoading(false)),
      )
      .catch(() =>
        setStatusMsg({
          type: "error",
          text: "Whoops! Something unexpected happened.",
        }),
      )
      .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    if (targetFolder) {
      moveToFolder(id!, targetFolder)
        .then(() => {
          setStatusMsg({ type: "success", text: "Asset moved successfully." });
          refreshAsset();
        })
        .catch((err) => {
          console.error(err);
          setStatusMsg({
            type: "error",
            text: "Whoops! There is was problem. Please try again.",
          });
        });
    }
  }, [targetFolder, refreshAsset, id]);

  const handleAssetRename = useCallback(
    (newname: string) => {
      if (newname.endsWith(`.${asset!.extension}`)) {
        newname = newname.substring(
          0,
          newname.lastIndexOf(`.${asset!.extension}`),
        );
      }
      updateAssetName(asset!.id, newname)
        .then(refreshAsset)
        .catch(() =>
          setStatusMsg({
            type: "error",
            text: "Whoops! There is was problem. Please try again.",
          }),
        );
    },
    [asset, refreshAsset],
  );

  const deleteAssetHandler = async () => {
    let isSuccess = false;

    try {
      setShowDeleteConf(false);
      setIsLoading(true);
      await deleteAsset(asset!.id, isStale);
      isSuccess = true;
    } catch (err: any) {
      if (err.message.includes("specified blob does not exist")) {
        setIsStale(true);
        setStatusMsg({
          type: "error",
          text: "Glitch! Please try again.",
        });
      } else {
        setStatusMsg({
          type: "error",
          text: "Whoops! There was problem deleting this asset.",
        });
      }
    }

    setIsLoading(false);
    if (isSuccess) {
      onBack();
    }
  };

  if (!isLoading) {
    if (!asset) {
      return (
        <div
          style={{ padding: "2% 5%", margin: "10% 0%" }}
          className="divCenterAlign"
        >
          <img src={getEmptyIcon()} width={"10%"} alt="empty" />

          <span
            style={{ margin: "0% 2%", fontSize: "larger", fontWeight: "bold" }}
          >
            Sorry! This file appears to have been removed. Please contact the
            author for the correct url.
          </span>
        </div>
      );
    } else {
      return (
        <div className="app-body-no-vertical">
          <StatusMessageComponent message={statusMsg} />

          <DeleteConfirmationDrawerComponent
            open={showDeleteConf}
            onDeleteHandler={deleteAssetHandler}
            onCancel={() => setShowDeleteConf(false)}
            title={`Are you sure you want to delete this asset?`}
            subtitle="Be sure to check if it is not used in any articles."
          />

          <div className="divSpread" style={{ marginBottom: 40 }}>
            <span className="hyperlink" onClick={onBack}>
              Back
            </span>

            <div style={{ maxWidth: "500px" }} className="divFlex">
              <EditableTextLabelComponent
                defaultValue={asset.name}
                textid="asset-name-text"
                spanid="asset-name-span"
                size="medium"
                onUpdateHandler={(newName: string) =>
                  handleAssetRename(newName)
                }
                maxWidth={"300px"}
              />
            </div>

            <div className="divCenterAlign">
              <FolderIcon />
              <span style={{ margin: "0px 10px" }}>{folderName}</span>
            </div>
          </div>

          {!isThumbnailAvailable && (
            <div style={{ padding: 20 }}>
              <span className="errorMsg">
                This asset does not have thumbnail.{" "}
              </span>
              <Button onClick={() => publishThumbnailHandler()}>
                Publish Thumbnail
              </Button>
            </div>
          )}

          <div className="divFlex">
            <div style={{ width: "80%" }}>
              <img
                src={asset.url}
                alt={"Not found.."}
                loading="lazy"
                style={{ maxWidth: "80%", height: "auto" }}
              />
            </div>

            <div
              className="divColumn"
              style={{ width: "20%" }}
              id="where-used-component"
            >
              <div style={{ marginBottom: 10 }}>
                <h3>Created at</h3>
                <span style={{ margin: "0px 10px" }}>
                  {new Date(asset.createdDate).toLocaleDateString()}
                </span>
              </div>

              <div style={{ marginBottom: 10 }}>
                <h3>Type</h3>
                <span style={{ margin: "0px 10px" }}>{asset.extension}</span>
              </div>

              <div style={{ marginBottom: 10 }}>
                <h3> Move to?</h3>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="move-to-folder-select"
                    value={targetFolder}
                    onChange={(e: SelectChangeEvent) =>
                      setTargetFolder(e.target.value)
                    }
                    displayEmpty
                  >
                    <MenuItem value={undefined} key={0}>
                      -- Choose Folder --
                    </MenuItem>

                    {folders &&
                      folders
                        .filter(
                          (folder: IFolder) => folder._id !== asset.folderId,
                        )
                        .map((folder: IFolder, i: number) => (
                          <MenuItem value={folder._id} key={i + 1}>
                            {folder.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </div>

              <div id="where-used-component" style={{ marginBottom: 80 }}>
                <h3> Where Used?</h3>
                <div>
                  <ul>
                    {whereUsed &&
                      whereUsed.map((article: IArticle, index: number) => {
                        return (
                          <li key={index}>
                            <Link
                              to={`/articles/${article._id}/edit`}
                              style={{ textTransform: "capitalize" }}
                            >
                              {article.title}{" "}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <Tooltip
                title={
                  whereUsed.length > 0 ? "This asset is used in articles." : ""
                }
              >
                <span>
                  <Button
                    id="delete-asset-button"
                    disabled={whereUsed.length > 0}
                    variant="outlined"
                    color="error"
                    onClick={() => setShowDeleteConf(true)}
                  >
                    {" "}
                    Delete{" "}
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      );
    }
  }

  return <SpinnerComponent />;
}

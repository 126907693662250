import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import app from "./reducers/appReducer";
import publicationsSaga from "./sagas/PublicationSaga";
import foldersSaga from "./sagas/FoldersSaga";
import subscriptionSaga from "./sagas/SubscriptionSaga";

const sagaMiddleware = createSagaMiddleware();

const rootReducers = combineReducers({ app });

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducers,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(publicationsSaga);
sagaMiddleware.run(subscriptionSaga);
sagaMiddleware.run(foldersSaga);

export default store;

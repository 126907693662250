import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useCallback, useState } from "react";
import { createFolder, updateFoldername } from "../../services/FoldersService";

interface IProps {
  folderId?: string;
  onSuccess: () => void;
  onError: (message: string) => void;
  onCancel: () => void;
  placeholder: string;
  mode: "create" | "rename";
}

export default function CreateFolderComponent({
  mode,
  placeholder,
  folderId,
  onError,
  onSuccess,
  onCancel,
}: IProps) {
  const [value, setValue] = useState<string>("");

  const handleOperation = useCallback(() => {
    const operation =
      mode === "create"
        ? createFolder(value, folderId)
        : updateFoldername(folderId!, value);

    operation
      .then(() => onSuccess())
      .catch((err: any) => {
        let message =
          "Whoops! Something unexpected happened. Please try again!";
        try {
          const error = JSON.parse(err.message);
          message = error.message;
        } catch (err: any) {
          // silently ignore
          console.error(err);
        }
        onError(message);
      });
  }, [folderId, onSuccess, onError, value, mode]);

  return (
    <div className="divFlex" style={{ margin: "20px 10px" }}>
      <TextField
        error={undefined}
        sx={styles.textField}
        inputProps={styles.textInputProps}
        id={mode === "create" ? "create-folder-text" : "rename-folder-text"}
        placeholder={placeholder}
        value={value}
        variant="standard"
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        disabled={value.length === 0}
        id="create-folder-button"
        onClick={() => handleOperation()}
        variant="contained"
        size="large"
        sx={styles.button}
      >
        {mode === "create" ? "Add" : "Update"}
      </Button>

      <Button
        id="create-folder-cancel"
        onClick={onCancel}
        variant="outlined"
        size="large"
        sx={styles.button}
      >
        Cancel
      </Button>
    </div>
  );
}

const styles = {
  button: {
    margin: "0px 10px",
  },
  textField: {
    width: "20vw",
    margin: "0px 40px 0px 0px",
  },
  textInputProps: {
    maxLength: 45,
    style: {
      fontSize: "20px",
    },
  },
};

import React from "react";
import { Link } from "react-router-dom";
import { getHostURLExpenseHut } from "../helpers/URLHelper";
import FooterComponent from "./FooterComponent";
import "./LeftPaneComponent.css";
import LogoComponent from "./LogoComponent";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default class LeftPaneMobileComponent extends React.Component {
  render() {
    return (
      <div className="left-pane-container">
        <div>
          <LogoComponent />

          <div id="menu">
            <ul>
              <li>
                <div>
                  <Link to="/">Home</Link>
                </div>
              </li>

              <li>
                <div>
                  <a
                    style={{ display: "flex", alignItems: "center" }}
                    href={`${getHostURLExpenseHut()}`}
                  >
                    ExpenseHut
                  </a>
                </div>
              </li>

              <li>
                <a
                  href={`${getHostURLExpenseHut()}/register`}
                  className="hyperlink"
                >
                  {" "}
                  Register{" "}
                </a>
              </li>

              <li>
                <a
                  href={`https://www.help.expensehut.com`}
                  target="blank"
                  rel="no-referrer"
                  className="hyperlink"
                  style={{ display: "flex" }}
                >
                  {" "}
                  Help
                  <OpenInNewIcon
                    sx={{ width: "20px", marginLeft: "3px" }}
                  ></OpenInNewIcon>{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <FooterComponent />
        </div>
      </div>
    );
  }
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../types/appTypes";
import LoginComponent from "../login/LoginComponent";
import { IdleTimerProvider } from "react-idle-timer";
import TimeoutDialogComponent from "../../components/shared/TimeoutDialogComponent";

interface IProps {
  user?: IUser;
  isMobile: boolean;
}

export default function SecuredRoutes({
  children,
  user,
  isMobile,
}: React.PropsWithChildren<IProps>) {
  const navigate = useNavigate();
  const [showTimeout, setShowTimeout] = useState<boolean>(false);

  const onIdle = () => {
    setShowTimeout(true);
  };

  if (user) {
    return (
      <IdleTimerProvider timeout={1000 * 15 * 60} onIdle={onIdle}>
        <TimeoutDialogComponent
          open={showTimeout}
          handleClose={() => setShowTimeout(false)}
        />
        {children}
      </IdleTimerProvider>
    );
  }

  return <LoginComponent isMobile={isMobile} navigate={navigate} />;
}

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface ISpinnerProps {
  size?: number;
}

export default function SpinnerComponent({ size }: ISpinnerProps) {
  return (
    <div
      style={{
        display: "flex",
        zIndex: 2,
        justifyContent: "center",
        height: "50%",
        alignItems: "center",
      }}
    >
      <CircularProgress color="secondary" size={size} />
    </div>
  );
}

interface IProps {
  percent?: number;
}

export function SpinnerWithProgressComponent({ percent }: IProps) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress value={percent} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${percent}%`}</Typography>
      </Box>
    </Box>
  );
}

import EditorJS from "@editorjs/editorjs";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getEmptyIcon } from "../helpers/IconHelper";
import { IPublication } from "../types/appTypes";

const Embed = require("@editorjs/embed");
const List = require("@editorjs/list");
const SimpleImage = require("@editorjs/simple-image");
const InlineCode = require("@editorjs/inline-code");
const CodeTool = require("@editorjs/code");
const Header = require("@editorjs/header");

interface IProps {
  publication: IPublication | undefined;
  /**
   * used to show home link. This should be undefined unless required since exhut help also uses this component.
   */
  showHome?: boolean;
  noHeader?: boolean;
  isMobile: boolean;
  onCopyLink?: () => void;
}

export default function PublicationsBodyViewComponent2({
  publication,
  showHome,
  onCopyLink,
  noHeader,
}: IProps) {
  const editorJS = useRef<EditorJS>();

  useEffect(() => {
    editorJS.current = new EditorJS({
      holder: "editorjs",
      readOnly: true,
      tools: {
        header: {
          class: Header as any,
          inlineToolbar: true,
        },
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              coub: true,
            },
          },
        },
        image: SimpleImage,
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+M",
        },
        code: CodeTool,
      },
      data: publication?.bodyContent,
    });
  }, [publication]);

  if (publication) {
    return (
      <div style={{ padding: "2% 5%" }}>
        <div className={showHome ? "divSpread" : "divRight"}>
          {showHome && <Link to="/">Home</Link>}

          {!noHeader && (
            <div className="divColumn">
              <div style={{ padding: "20px 20px 10px 0px" }}>
                <strong style={{ margin: "0px 5px" }}> Authored by : </strong>
                {(publication.user?.lastname ?? "") +
                  ", " +
                  (publication?.user?.firstname ?? "<Name not found>")}
              </div>
              <div style={{ padding: "10px 20px 10px 0px" }}>
                <strong style={{ margin: "0px 5px" }}>
                  {" "}
                  Published Date :{" "}
                </strong>

                {publication.publishedDate &&
                  new Date(publication?.publishedDate).toLocaleDateString()}
              </div>
              <div style={{ padding: "10px 20px 20px 0px" }}>
                <strong style={{ margin: "0px 5px" }}> Views : </strong>

                {publication.views?.length ?? 0}
              </div>
            </div>
          )}
        </div>

        {onCopyLink && (
          <div>
            <Tooltip title="Copy article link">
              <InsertLinkIcon sx={{ cursor: "pointer" }} onClick={onCopyLink} />
            </Tooltip>
          </div>
        )}

        {!noHeader && <hr />}

        <div id="editorjs"></div>
      </div>
    );
  }
  return (
    <div
      style={{ padding: "2% 5%", margin: "10% 0%" }}
      className="divCenterAlign"
    >
      <img src={getEmptyIcon()} width={"10%"} alt="empty" />

      <span style={{ margin: "0% 2%", fontSize: "larger", fontWeight: "bold" }}>
        Sorry! This article appears to have been removed. Please contact the
        author for the correct url.
      </span>
    </div>
  );
}

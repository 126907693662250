import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./EditArticleComponent.css";

export default function EditorJsHelpComponent() {
  return (
    <div
      className="divColumn"
      style={{
        backgroundColor: "var(--exhut-light-grey5)",
        padding: 20,
        border: "1px solid green",
        borderRadius: "30px",
      }}
    >
      <strong style={{ color: "var(--exhut-orange)" }}>Tips: </strong>

      <div className="divSpread">
        <div className="my-20" style={{ width: "30%" }}>
          <strong>To Add Content:</strong>

          <ul
            className="my-20"
            style={{
              color: "var(--exhut-dark-grey)",
              listStyle: "disc",
              marginLeft: 20,
            }}
          >
            <li>
              Click + button in the editor to choose Text, Heading or other
              content.
            </li>
            <li>Then, start typing.</li>
          </ul>
        </div>

        <div className="my-20" style={{ width: "30%" }}>
          <strong>To Modify Content:</strong>

          <ul
            className="my-20"
            style={{
              color: "var(--exhut-dark-grey)",
              listStyle: "disc",
              marginLeft: 20,
            }}
          >
            <li>
              <div className="divAlignItemsOnly">
                Click <DragIndicatorIcon className="mx-5" /> button .
              </div>
            </li>
            <li>Then, choose the action.</li>
          </ul>
        </div>

        <div className="flex-1" style={{ width: "30%" }}>
          <strong>To Add Images:</strong>

          <ul
            className="my-20"
            style={{
              color: "var(--exhut-dark-grey)",
              listStyle: "disc",
              marginLeft: 20,
            }}
          >
            <li>
              Click + button at the bottom right to open assets panel from the
              right.
            </li>
            <li>
              <div className="divAlignItemsOnly">
                Scroll to the image and click + to add.
              </div>
            </li>
            <li>To upload images, go to Assets page and upload.</li>
          </ul>
        </div>
      </div>

      <div></div>
    </div>
  );
}

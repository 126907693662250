import React from "react";
import { Link } from "react-router-dom";

export default class LogoComponent extends React.Component {
  render() {
    return (
      <div
        style={{
          padding: 30,
          cursor: "pointer",
          width: "-webkit-fill-available",
        }}
        onClick={() => {}}
      >
        <div
          style={{
            border: "3px solid white",
            borderRadius: 10,
            padding: 10,
            textAlign: "center",
          }}
        >
          <Link
            to="/home"
            style={{
              fontSize: "30px",
              color: "white",
              textDecorationLine: "none",
            }}
          >
            Blogs
          </Link>
        </div>
        <div style={{ textAlign: "end", padding: 5 }}>
          <span style={{ color: "white", fontWeight: "" }}>by </span>
          <span style={{ color: "white", fontWeight: "bold" }}>expense</span>
          <span style={{ color: "yellow", fontWeight: "bold" }}>hut</span>
        </div>
      </div>
    );
  }
}

import { Button, Tooltip } from "@mui/material";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

interface IAssetToolBarProps {
  onUploadAssetClick: () => void;
  onCreateFolderCick: () => void;
  isRootFolder: boolean;
  onRenameFolderClick: () => void;
  onDeleteFolderClick: () => void;
}

export default function AssetsToolBar({
  onUploadAssetClick,
  onCreateFolderCick,
  onDeleteFolderClick,
  onRenameFolderClick,
  isRootFolder,
}: IAssetToolBarProps) {
  return (
    <div>
      <Tooltip title="Upload asset">
        <Button id="upload-asset-btn" onClick={onUploadAssetClick}>
          <NoteAddIcon
            style={{
              width: 40,
              height: 40,
              cursor: "pointer",
              margin: "0px 10px",
              color: "var(--exhut-orange)",
            }}
          />
        </Button>
      </Tooltip>

      <Tooltip title="Create SubFolder">
        <Button id="create-folder-btn" onClick={onCreateFolderCick}>
          <CreateNewFolderIcon
            style={{
              width: 40,
              height: 40,
              cursor: "pointer",
              margin: "0px 10px",
              color: "var(--exhut-blue)",
            }}
          />
        </Button>
      </Tooltip>

      {!isRootFolder && (
        <>
          <Tooltip title="Rename Folder">
            <Button id="rename-folder-btn" onClick={onRenameFolderClick}>
              <EditIcon
                style={{
                  width: 40,
                  height: 40,
                  cursor: "pointer",
                  margin: "0px 10px",
                  color: "var(--exhut-light-grey0)",
                }}
              />
            </Button>
          </Tooltip>

          <Tooltip title="Delete Folder">
            <Button id="delete-folder-btn" onClick={onDeleteFolderClick}>
              <DeleteIcon
                style={{
                  width: 40,
                  height: 40,
                  cursor: "pointer",
                  margin: "0px 10px",
                  color: "var(--exhut-dark-red)",
                }}
              />
            </Button>
          </Tooltip>
        </>
      )}
    </div>
  );
}

import EditorJS, { OutputData } from "@editorjs/editorjs";
import {
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SpeedDial from "@mui/material/SpeedDial";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getPublications } from "../../actions/publicationsAction";
import { getHostURL } from "../../helpers/URLHelper";
import {
  getArticleById,
  updateArticleAttributes,
  updateArticleBody,
  updateArticleVisibility,
} from "../../services/ArticleService";
import {
  createPublication,
  getAllPublications,
} from "../../services/PublicationService";
import store from "../../store";
import { IArticle, IFolderData, IPublication } from "../../types/appTypes";
import EditableTextLabelComponent from "../shared/EditableTextLabelComponent";
import InfoComponent from "../shared/InfoComponent";
import StatusMessageComponent, {
  IStatusMsg,
} from "../shared/StatusMessageComponent";
import AllAssetsEditorComponent from "./AllAssetsEditorComponent";
import AllPublicationsComponent from "./AllPublicationsComponent";
import "./EditArticleComponent.css";
import EditorJsHelpComponent from "./EditorJsHelpComponent";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import EditorJsMigratorComponent from "./EditorJsMigratorComponent";
import SpinnerComponent from "../shared/SpinnerComponent";
import EditArticleNotFoundComponent from "./EditArticleNotFoundComponent";
import { parseStringToEditorJsBlock } from "../../helpers/HtmlHelper";
import ConfirmationDialog from "../shared/ConfirmationDialog";

const Header = require("@editorjs/header");

const Embed = require("@editorjs/embed");
const List = require("@editorjs/list");
const SimpleImage = require("@editorjs/simple-image");
const InlineCode = require("@editorjs/inline-code");
const CodeTool = require("@editorjs/code");
const Paragraph = require("@editorjs/paragraph");

export default function EditArticleComponentNew() {
  const [showAllAssets, setShowAllAssets] = useState<boolean>(false);
  const [article, setArticle] = useState<IArticle>();
  const { id: articleId } = useParams();
  const editorJS = useRef<EditorJS>();
  const [publications, setPublications] = useState<IPublication[]>([]);
  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [canPublish, setCanPublish] = useState<boolean>(false);
  const [showPublications, setShowPublications] = useState<boolean>(false);
  const [isSaveProgress, setSaveProgress] = useState<boolean>(false);
  const [incorrectFormat, setIncorrectFormat] = useState<boolean>(false);
  const [fullview, setFullView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showArticleLimitDialog, setShowArticleLimitDialog] =
    useState<boolean>(false);

  useEffect(() => {
    if (articleId) {
      getArticleById(articleId)
        .then((article: IArticle) => setArticle(article))
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [articleId]);

  const initializeEditor = (initContent: OutputData) => {
    editorJS.current = new EditorJS({
      holder: "editorjs",
      placeholder: "Hover mouse here, and click +, then start typing...",
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            preserveBlank: true,
          },
        },
        header: {
          class: Header as any,
          inlineToolbar: true,
        },
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              coub: true,
            },
          },
        },
        image: SimpleImage,
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+M",
        },
        code: CodeTool,
      },
      data: initContent,
    });
  };

  useEffect(() => {
    if (article) {
      if (typeof article?.bodyContent === "object") {
        getAllPublications(article?._id).then((pubs: IPublication[]) =>
          setPublications(pubs),
        );

        if (!editorJS.current) {
          initializeEditor(article.bodyContent);
        }
      } else {
        setIncorrectFormat(true);
      }
    }
  }, [article]);

  const getEditorContent = () => {
    return editorJS.current!.save();
  };

  const renameArticle = async (articleName: string) => {
    try {
      await updateArticleAttributes(articleId!, { name: articleName });
      setStatusMsg({ type: "success", text: "Article saved successfully." });
    } catch (err) {
      setStatusMsg({
        type: "error",
        text: "Whoops! Something went wrong saving article.",
      });
    }
  };

  const preprocessArticleContent = async () => {
    const content = await getEditorContent();
    const hasEmbeddedImages = content.blocks.filter(
      (b) => b.type === "image" && b.data.url.includes("base64"),
    );
    if (hasEmbeddedImages.length > 1) {
      // warn only if more than 1
      setShowArticleLimitDialog(true);
    } else {
      await saveArticle();
    }
  };

  const saveArticle = async () => {
    try {
      setShowArticleLimitDialog(false);
      setSaveProgress(true);
      const content = await getEditorContent();
      await updateArticleBody(articleId!, content);
      setStatusMsg({
        type: "success",
        text: "Article saved successfully.",
      });
    } catch (err: any) {
      // console.log("Saving failed: ", err);
      if (err.status === 400) {
        setStatusMsg({
          type: "error",
          text: err.message,
        });
      } else if (err.status === 415) {
        setStatusMsg({
          type: "error",
          text: "Article contains bigger sized images. Please upload them as assets and add them in articles.",
        });
      } else {
        setStatusMsg({
          type: "error",
          text: "Whoops! Something went wrong saving article. If you have copy-pasted images, please reduce sizes and try again.",
        });
      }
    } finally {
      setSaveProgress(false);
      setCanPublish(true);
    }
  };

  const publishArticleHandler = async () => {
    try {
      if (articleId) {
        const bodyContent = await getEditorContent();
        await createPublication(articleId, bodyContent, [], "v2");
        setStatusMsg({
          type: "success",
          text: "Publish success",
        });
        store.dispatch(getPublications(articleId));
        setCanPublish(false);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const updateVisibility = async (visibility: boolean) => {
    let isSuccess = false;
    try {
      setSaveProgress(true);

      await updateArticleVisibility(articleId!, visibility);
      isSuccess = true;
      const article = await getArticleById(articleId!);
      setArticle(article);
    } catch (err) {
      console.error(err);
    }
    setTimeout(() => {
      setSaveProgress(false);

      if (isSuccess) {
        setStatusMsg({
          type: "success",
          text: `Article set to ${visibility ? "public" : "private "} mode.`,
        });
      } else {
        setStatusMsg({
          type: "error",
          text: "Whoops! Error updating visibility.",
        });
      }
    }, 1000);
  };

  const copyPublicationLinkHandler = () => {
    window.navigator.clipboard.writeText(
      `${getHostURL()}/articles/${articleId}/published`,
    );
    setStatusMsg({
      type: "success",
      text: "Perma link successfully copied to clipboard.",
    });
  };

  const handleAddImageBlock = (folderDataItem: IFolderData) => {
    editorJS.current?.blocks.insert("image", {
      url: folderDataItem.url,
      caption: folderDataItem.name,
      withBorder: false,
      withBackground: false,
      stretched: false,
    });
  };

  const handleConversion = () => {
    setIncorrectFormat(false);
    const parsedBlocks = parseStringToEditorJsBlock(
      article?.bodyContent as string,
    );
    initializeEditor({
      version: "2.29.0",
      time: Date.now(),
      blocks: parsedBlocks,
    });
  };

  if (!isLoading) {
    if (!article) {
      return <EditArticleNotFoundComponent />;
    } else if (incorrectFormat) {
      return <EditorJsMigratorComponent handleConversion={handleConversion} />;
    } else {
      return (
        <div id="edit-article-container">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSaveProgress}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <ConfirmationDialog
            open={showArticleLimitDialog}
            title="Possible excessive usage of assets."
            subtitles={[
              "We noticed you have images in this article that were either drag-dropped or pasted directly using Ctrl + V.",
              "These images will be counted as separate images towards your usage in ExpenseHut even if they are the same.",
              "In order to avoid this, please upload these images from Assets page then include them in your article.",
            ]}
            onClose={() => setShowArticleLimitDialog(false)}
            onProceed={saveArticle}
            failureBtnLabel={"Go Back And Edit"}
            successBtnLabel="I'm fine with it."
          />

          <AllAssetsEditorComponent
            onClose={() => setShowAllAssets(false)}
            open={showAllAssets}
            onAddAsset={(item: IFolderData) => {
              handleAddImageBlock(item);
              setShowAllAssets(false);
            }}
          />

          <AllPublicationsComponent
            onClose={() => setShowPublications(false)}
            open={showPublications}
            articleId={articleId}
          />

          <StatusMessageComponent
            message={statusMsg}
            style={{ position: "fixed", width: "70%" }}
          />

          <div hidden={fullview}>
            <div className="divSpread mt-30">
              <Link to="/home" className="hyperlink-green">
                Back
              </Link>

              <div className="divFlex">
                <EditableTextLabelComponent
                  defaultValue={article?.title ?? ""}
                  textid="article-text-id"
                  spanid="article-title-id"
                  onUpdateHandler={(articleName) => renameArticle(articleName)}
                  maxWidth={"400px"}
                />
              </div>

              <div className="divCenterAlign">
                <Tooltip title={"Save to publish this article."}>
                  <span>
                    <Button
                      id="publish-article"
                      variant="contained"
                      disabled={!canPublish}
                      sx={{ backgroundColor: "var(--exhut-green)" }}
                      onClick={() => publishArticleHandler()}
                    >
                      {" "}
                      Publish{" "}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className="divSpread">
              <div className="divCenterOnly">
                <h3 className="field">Visibility:</h3>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  row
                  value={article?.visibility === true ? "public" : "private"}
                  onChange={(e: any) =>
                    updateVisibility(e.target.value === "public")
                  }
                >
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Private"
                  />
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Public"
                  />
                </RadioGroup>
              </div>

              <Button
                id="all-publications"
                onClick={() => setShowPublications(true)}
              >
                All Publications{" "}
              </Button>
            </div>

            <div className="divRight">
              {publications?.length > 0 && !canPublish && (
                <div className="divCenterAlign">
                  <InfoComponent
                    tooltip={
                      article?.visibility === true
                        ? "Share this url publicly."
                        : "You must set article to Public."
                    }
                  />

                  <Button
                    onClick={copyPublicationLinkHandler}
                    disabled={article?.visibility !== true}
                  >
                    Share Article
                  </Button>
                </div>
              )}
            </div>

            <div className="divSpread" id="edit-article-version">
              <div className="divFlex">
                <h3>Version:</h3>
                <h3 className="mx-10">
                  {publications.length > 0 ? publications.length : "-"}
                </h3>
              </div>
              <Button
                id="save-article"
                variant="contained"
                sx={{ backgroundColor: "var(--exhut-green)" }}
                onClick={() => preprocessArticleContent()}
              >
                {" "}
                Save{" "}
              </Button>
            </div>

            <hr />

            <EditorJsHelpComponent />
          </div>

          <div className="divCenterAlign my-20">
            <Tooltip title={"Full page view"}>
              <span>
                <Button onClick={() => setFullView(!fullview)}>
                  {fullview && (
                    <KeyboardDoubleArrowDownIcon
                      fontSize="large"
                      sx={{ cursor: "pointer" }}
                    />
                  )}
                  {!fullview && (
                    <KeyboardDoubleArrowUpIcon
                      fontSize="large"
                      sx={{ cursor: "pointer" }}
                    />
                  )}
                </Button>
              </span>
            </Tooltip>
          </div>

          <div className="divFlex my-20">
            <Paper
              id="editor-component"
              elevation={6}
              className={"editor-card-full-width"}
            >
              <div id="editorjs"></div>
            </Paper>
          </div>

          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "fixed", bottom: 40, right: 40 }}
            onClick={() => setShowAllAssets(true)}
            icon={<h2> + </h2>}
            id="quick-add"
          ></SpeedDial>
        </div>
      );
    }
  }

  return <SpinnerComponent size={50} />;
}

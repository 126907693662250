import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export default function TimeoutDialogComponent({ open, handleClose }: IProps) {
  const navigate = useNavigate();
  // const location = useLocation();

  const refreshAppHandler = () => {
    navigate("/home");
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Still there?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          It looks like you've gone inactive for a while. No worries! Please
          click continue.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={refreshAppHandler} autoFocus>
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from "react";
import "./InstructionsComponent.css";

export default class InstructionsComponent extends React.Component {
  render() {
    return (
      <div id="instructions-container">
        <div>
          <h2>Welcome to Blogs.</h2>
          <h3>Create your article and publish in 3 simple steps.</h3>
        </div>

        <div className="divFlex" id="tile-container">
          <div
            className="tile"
            style={{ backgroundColor: "var(--exhut-total-green)" }}
          >
            <h2> Step 1</h2>
            <p>Click the Create button to add article.</p>
          </div>

          <div
            className="tile"
            style={{ backgroundColor: "var(--exhut-orange)" }}
          >
            <h2> Step 2</h2>
            <p>Click on the article to add your content.</p>
          </div>

          <div
            className="tile"
            style={{ backgroundColor: "var(--exhut-blue)" }}
          >
            <h2> Step 3</h2>
            <p>Hit Save and Publish.</p>
            <strong>Boom!</strong>
          </div>
        </div>
      </div>
    );
  }
}

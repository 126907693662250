import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PUBLICATIONS,
  setPublications,
} from "../actions/publicationsAction";
import * as Api from "../services/PublicationService";
import { IPublication } from "../types/appTypes";

function* fetchPublications(action: any) {
  try {
    const pubs: IPublication[] = yield call(
      Api.getAllPublications,
      action.payload,
    );
    yield put(setPublications(pubs));
  } catch (e) {
    console.error(e);
    yield put(setPublications([]));
  }
}

export default function* publicationsSaga() {
  yield takeEvery(GET_PUBLICATIONS, fetchPublications);
}

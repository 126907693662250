import React from "react";

export default class NotFoundComponent extends React.Component {
  render() {
    return (
      <div className="divCenter">
        <h3> Sorry! The page you are looking for does not exist.</h3>
      </div>
    );
  }
}

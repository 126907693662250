import { IArticle } from "../types/appTypes";
import BlogConnection from "./Connection";
import { executeService2 } from "./FetchService";

export function getAllArticles(): Promise<IArticle[]> {
  const url = new BlogConnection().getURL() + "/articles";
  return executeService2(url);
}

export function getArticleById(id: string): Promise<IArticle> {
  const url = new BlogConnection().getURL() + "/articles/" + id;
  return executeService2(url);
}

export function createArticle(title: string, subtitle?: string, body?: string) {
  const url = new BlogConnection().getURL() + "/articles";
  const capitalizedTitle =
    title.substring(0, 1).toUpperCase() + title.substring(1);
  return executeService2(url, {
    method: "POST",
    payload: { heading: capitalizedTitle, subheading: subtitle, body },
    respCode: 201,
  });
}

export function updateArticleAttributes(
  id: string,
  { name }: { name: string },
) {
  const url = new BlogConnection().getURL() + "/articles/" + id + "/attributes";
  return executeService2(url, { method: "PUT", payload: { name } });
}

export function updateArticleBody(id: string, body?: any, assetIds?: string[]) {
  const url = new BlogConnection().getURL() + "/articles/" + id + "/body";
  return executeService2(url, { method: "PUT", payload: { body } });
}

export function updateArticleVisibility(id: string, visibility: boolean) {
  const url =
    new BlogConnection().getURL() +
    "/articles/" +
    id +
    "/visibility?visibility=" +
    visibility;
  return executeService2(url, { method: "PUT", respType: "restrict" });
}

export function deleteArticle(id: string) {
  const url = new BlogConnection().getURL() + "/articles/" + id;
  return executeService2(url, {
    method: "DELETE",
    respCode: 204,
    respType: "restrict",
  });
}

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAsset, getWhereUsed } from "../../services/AssetsService";
import { IArticle, IAsset, ICurrentItem } from "../../types/appTypes";

interface IProps {
  whereUsed: ICurrentItem | undefined;
  onClose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function WhereUsedInLineComponent(props: IProps) {
  const [whereUsedArticles, setWhereUsedArticles] = useState<IArticle[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [asset, setAsset] = useState<IAsset>();

  useEffect(() => {
    if (props.whereUsed) {
      getAsset(props.whereUsed!.id!)
        .then((asset: IAsset) => setAsset(asset))
        .catch((err) => {
          console.error(err);
          setError(true);
        });
      getWhereUsed(props.whereUsed!.id!)
        .then((values) => setWhereUsedArticles(values))
        .catch((err) => {
          console.error(err);
          setError(true);
        });
    }
  }, [props.whereUsed]);

  return (
    <div>
      <Modal
        open={!!props.whereUsed}
        onClose={props.onClose}
        onBackdropClick={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="">
            <div className="divCenter">
              <h3> Where Used </h3>
            </div>

            {!error && (
              <>
                <div className="divCenter">
                  <strong>{asset?.name} </strong>
                </div>

                <div
                  className="divCenter"
                  style={{ padding: 10, margin: "20px 0px " }}
                >
                  <ul>
                    {whereUsedArticles.map((article: IArticle, key: number) => (
                      <li key={key}>
                        <Link
                          to={`/articles/${article._id}`}
                          style={{ textTransform: "capitalize" }}
                        >
                          {article.title}{" "}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}

            {error && (
              <div
                className="divCenter"
                style={{ padding: 10, margin: "20px 0px " }}
              >
                <span className="errorMsg">
                  Error fetching data. Please try after sometime.{" "}
                </span>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import React from "react";
import LogoComponent from "./LogoComponent";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";

interface IProps {
  isMobile?: boolean;
  onToggleOptions?: () => void;
  showOptions?: any;
}
export default class LogoWithBackgroundComponent extends React.Component<IProps> {
  render() {
    return (
      <div
        className=""
        style={{ backgroundColor: "var(--exhut-primary)", display: "flex" }}
      >
        {this.props.showOptions && (
          <div style={{ width: "20%" }} className="divCenter">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.props.onToggleOptions}
              edge="start"
            >
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
          </div>
        )}

        <LogoComponent />
      </div>
    );
  }
}

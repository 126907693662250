export const config = {
  googleClientId:
    "584557405125-peujiivtkv5be7qcg4bu7a3k3ncd3ahn.apps.googleusercontent.com",
};

const host = "localhost"; // localhost

export class AuthConnection {
  getURL() {
    return process.env.REACT_APP_EXHUT_AUTH_URL || `http://${host}:2001`;
  }
}

export default class BlogConnection {
  getURL() {
    return process.env.REACT_APP_EXHUT_BLOGS_BE_URL || `http://${host}:3005`;
  }
}

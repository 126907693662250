import { Divider, List } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { IArticle } from "../../types/appTypes";
import { getFeeds } from "../../services/FeedsService";
import "./WhatsTrendingComponent.css";
import moment from "moment";

export default function WhatsTrendingComponent() {
  const [publicArticles, setPublicArticles] = React.useState<IArticle[]>([]);

  useEffect(() => {
    getFeeds()
      .then((articles: IArticle[]) => setPublicArticles(articles))
      .catch((err: any) => console.error(err));
  }, []);

  return (
    <div
      style={{ flex: 1, backgroundColor: "black" }}
      hidden={publicArticles?.length === 0}
    >
      <div className="trending-header">
        <div className="greeting-infinite">
          <h4> What's trending?</h4>
        </div>

        <div className="divCenterAlign">
          {<Link to="/feeds">View All</Link>}
        </div>
      </div>

      <List
        sx={{
          width: "100%",
          bgcolor: "black",
          marginBottom: 3,
          display: publicArticles?.length > 0 ? "visible" : "none",
        }}
      >
        {publicArticles &&
          publicArticles.map((article: IArticle, index: number) => {
            return (
              <Link
                to={`/articles/${article._id}/published?q=${article.title?.trim().split(" ").join("+")}`}
                className="hyperlink-no-link"
                key={index}
              >
                <ListItem
                  alignItems="flex-start"
                  sx={{ cursor: "pointer", color: "black" }}
                >
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp">
                      {article.publications?.[0].user?.firstname?.substring(
                        0,
                        1,
                      ) ?? "?"}{" "}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        variant="h6"
                        sx={{ color: "white" }}
                      >
                        {article.title}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="var(--exhut-yellow)"
                        >
                          {`${article.publications[0].user?.lastname}, ${article.publications[0].user?.firstname} `}
                        </Typography>

                        <span className="infoMsg">
                          -{" "}
                          {moment(
                            article.publications[
                              article.publications.length - 1
                            ].publishedDate,
                          ).format("DD MMM YYYY")}
                        </span>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </Link>
            );
          })}
      </List>
    </div>
  );
}

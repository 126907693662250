import { call, put, takeEvery } from "redux-saga/effects";
import { GET_FOLDERS, setFolders } from "../actions/foldersAction";
import * as Api from "../services/FoldersService";
import { IFolder } from "../types/appTypes";

function* fetchFolders() {
  try {
    const folders: IFolder[] = yield call(Api.getAllFolders);
    yield put(setFolders(folders));
  } catch (e) {
    console.error(e);
  }
}

export default function* foldersSaga() {
  yield takeEvery(GET_FOLDERS, fetchFolders);
}

import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { setUser } from "../actions/appAction";
import { session } from "../services/AuthService";
import { updateProfile } from "../services/UserService";
import store from "../store";
import { IUser } from "../types/appTypes";
import StatusMessageComponent, {
  IStatusMsg,
} from "./shared/StatusMessageComponent";

interface IProps {
  user: IUser;
}
interface IState {
  firstnameEditText: string;
  lastnameEditText: string;
  statusMsg: IStatusMsg | null;
}
export default class UpdateProfileComponent extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      firstnameEditText: "",
      lastnameEditText: "",
      statusMsg: null,
    };
    this.updateProfileHandler = this.updateProfileHandler.bind(this);
  }
  async updateProfileHandler() {
    try {
      await updateProfile(
        this.state.firstnameEditText,
        this.state.lastnameEditText,
      );
      const user = await session();
      store.dispatch(setUser(user));
    } catch (err: any) {
      console.error(err);
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! Something went wrong updating the profile. Please try again.",
        },
      });
    }
  }

  render() {
    return (
      <div style={{ padding: "2% 2%" }}>
        <StatusMessageComponent message={this.state.statusMsg} />

        <div className="divColumn">
          <span className="greeting">
            <h1> Hi There,</h1>
          </span>
          <span className="greeting-body">
            It appears you have not completed your ExpenseHut profile.
          </span>
        </div>
        <div style={{ padding: "20px 20px" }}>
          <div style={{ padding: "20px 0px" }}>
            <TextField
              sx={{ width: "20vw", margin: "0px 20px" }}
              InputProps={{
                style: {
                  fontSize: "20px",
                },
              }}
              required
              id="firstname"
              placeholder="First name.."
              defaultValue=""
              variant="standard"
              onChange={(e) =>
                this.setState({ firstnameEditText: e.target.value })
              }
            />

            <TextField
              sx={{ width: "20vw", margin: "0px 20px" }}
              InputProps={{
                style: {
                  fontSize: "20px",
                },
              }}
              required
              id="lastname"
              placeholder="Last name.."
              defaultValue=""
              variant="standard"
              onChange={(e) =>
                this.setState({ lastnameEditText: e.target.value })
              }
            />
          </div>

          <Button
            sx={{
              backgroundColor: "var(--exhut-green)",
              width: "8vw",
              margin: "0px 20px",
            }}
            id="save"
            variant="contained"
            disabled={
              this.state.firstnameEditText.trim().length === 0 ||
              this.state.lastnameEditText.trim().length === 0
            }
            onClick={this.updateProfileHandler}
            size="large"
          >
            Save
          </Button>
        </div>

        <div></div>
      </div>
    );
  }
}

import FolderIcon from "@mui/icons-material/Folder";
import React from "react";
import { IFolderData } from "../../types/appTypes";
import "../../styles/TileComponent.css";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
interface IImageTileProps {
  folderData: IFolderData[];
  onSelect: (data: IFolderData) => void;
  onWhereUsed?: (data: IFolderData) => void;
  onDelete?: (data: IFolderData) => void;
  size?: "medium" | "large";
  contextMenu?: boolean;
}

export default function TileComponent(props: IImageTileProps) {
  return (
    <>
      {props.folderData.map((tileItem: IFolderData, i: number) => (
        <div className="tile" key={i} onClick={(e) => props.onSelect(tileItem)}>
          <ContextMenuTrigger id={tileItem.id}>
            {tileItem.type === "image" && (
              <img
                id={tileItem.id}
                data-id={tileItem.name}
                src={tileItem.thumbnail}
                alt={"Loading..."}
                loading="lazy"
                style={{ height: "100%" }}
                onError={console.error}
              />
            )}

            {tileItem.type === "folder" && (
              <div className="folder-tile" data-id={tileItem.name}>
                <FolderIcon sx={{ fontSize: 40 }} />
                <span>{tileItem.name}</span>
              </div>
            )}
          </ContextMenuTrigger>

          {props.contextMenu && tileItem.type !== "folder" && (
            <ContextMenu
              id={tileItem.id}
              style={{
                backgroundColor: "lightgrey",
                padding: 10,
                border: "1px solid black",
              }}
            >
              <MenuItem
                className="asset-context-menu-item"
                onClick={(e) => {
                  e.stopPropagation();
                  // this.props.onSelect(tileItem)
                  e.stopPropagation();
                  props.onSelect && props.onSelect(tileItem);
                }}
              >
                View
              </MenuItem>
              <hr />
              <MenuItem
                className="asset-context-menu-item"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onDelete && props.onDelete(tileItem);
                }}
              >
                Delete
              </MenuItem>
              <hr />
              <MenuItem
                className={`asset-context-menu-item`}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onWhereUsed && props.onWhereUsed(tileItem);
                }}
              >
                Where Used
              </MenuItem>
            </ContextMenu>
          )}
        </div>
      ))}
    </>
  );
}

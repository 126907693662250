import React from "react";
import moment from "moment";
interface IProps {
  isMobile?: boolean;
}

export default class FooterComponent extends React.Component<IProps> {
  render() {
    return (
      <div className="footer">
        <div className="divSpread">
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: "yellow" }}
            href="https://www.help.expensehut.com/terms.html"
          >
            Terms{" "}
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: "yellow" }}
            href="https://www.help.expensehut.com/blogs/acceptableUse.html"
          >
            Acceptable Use{" "}
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: "yellow" }}
            href="https://www.help.expensehut.com/contactUsHelp.html"
          >
            Contact Us{" "}
          </a>
        </div>
        <div>
          {" "}
          A{" "}
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: "yellow" }}
            href="https://www.sabarishnarain.com"
          >
            SabarishNarain{" "}
          </a>{" "}
          Project
        </div>
        <div className="footer-copyright">
          {" "}
          &copy; {moment().format("YYYY")} www.blogs.expensehut.com{" "}
        </div>
      </div>
    );
  }
}

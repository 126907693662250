import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { resetAction } from "../actions/appAction";
import { getNameLabel } from "../helpers/TextHelper";
import { getHostURLExpenseHut } from "../helpers/URLHelper";
import { getSignature } from "../services/AppStorageService";
import { logout } from "../services/AuthService";
import store from "../store";
import { IUser } from "../types/appTypes";
import FooterComponent from "./FooterComponent";
import "./LeftPaneComponent.css";
import LogoComponent from "./LogoComponent";

interface IProps {
  user: IUser;
}
class LeftPaneComponent extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
    this.logoutHandler = this.logoutHandler.bind(this);
  }

  async logoutHandler() {
    try {
      store.dispatch(resetAction());
      await logout();
    } catch (err) {
      console.error(err);
    }
  }
  render() {
    return (
      <div className="left-pane-container">
        <div>
          <LogoComponent />

          <div id="menu">
            {this.props.user && (
              <ul>
                <li>
                  <div>
                    Hi,{" "}
                    <Link to="/home">
                      {" "}
                      {getNameLabel(this.props.user, 15)}{" "}
                    </Link>
                  </div>
                </li>
                <li>
                  <Link to="/assets"> Assets </Link>
                </li>

                <li>
                  <div>
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      href={`${getHostURLExpenseHut()}/home?exhut_token_ssid=${getSignature()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Expense Hut{" "}
                      <OpenInNewIcon
                        sx={{ width: "20px", marginLeft: "3px" }}
                      ></OpenInNewIcon>
                    </a>
                  </div>
                </li>

                <li>
                  <Link
                    to="/"
                    className="hyperlink"
                    onClick={() => this.logoutHandler()}
                  >
                    {" "}
                    Logout{" "}
                  </Link>
                </li>
              </ul>
            )}

            {!this.props.user && (
              <ul>
                <li>
                  <div>
                    <Link to="/" className="hyperlink">
                      {" "}
                      Login{" "}
                    </Link>
                  </div>
                </li>

                <li>
                  <div>
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      href={`${getHostURLExpenseHut()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      ExpenseHut{" "}
                      <OpenInNewIcon
                        sx={{ width: "20px", marginLeft: "3px" }}
                      ></OpenInNewIcon>
                    </a>
                  </div>
                </li>

                <li>
                  <a
                    href={`${getHostURLExpenseHut()}/register`}
                    target={"_blank"}
                    rel={"no-referrer noreferrer"}
                    className="hyperlink"
                  >
                    {" "}
                    Register{" "}
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div>
          <FooterComponent />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { user, groups } = state.app;
  return { user, groups };
}
export default connect(mapStateToProps)(LeftPaneComponent);

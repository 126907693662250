import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import "../../styles/TileComponent.css";
import { IFolderData } from "../../types/appTypes";
import { Tooltip } from "@mui/material";
interface IImageTileProps {
  folderData: IFolderData[];
  onSelect: (data: IFolderData) => void;
  onWhereUsed?: (data: IFolderData) => void;
  onDelete?: (data: IFolderData) => void;
  size?: "medium" | "large";
  contextMenu?: boolean;
  onAddAsset: (data: IFolderData) => void;
}

/**
 * This is different from the other TileComponent. This is used in All assets in editor component.
 * @param props
 * @returns
 */
export default function TileComponent(props: IImageTileProps) {
  return (
    <>
      {props.folderData.map((tileItem: IFolderData, i: number) => (
        <div className="mt-10">
          {tileItem.type === "image" && (
            <div className="divSpread my-5">
              <div style={{ width: "80%" }}>{tileItem.name}</div>
              <Tooltip title="Click to add to article">
                <AddIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => props.onAddAsset(tileItem)}
                />
              </Tooltip>
            </div>
          )}
          <div
            className="tile"
            key={i}
            onClick={(e) => props.onSelect(tileItem)}
          >
            {tileItem.type === "image" && (
              <img
                id={tileItem.id}
                data-id={tileItem.name}
                src={tileItem.thumbnail}
                alt={"Loading..."}
                loading="lazy"
                style={{ height: "100%" }}
                onError={console.error}
              />
            )}

            {tileItem.type === "folder" && (
              <div className="folder-tile" data-id={tileItem.name}>
                <FolderIcon sx={{ fontSize: 40 }} />
                <span>{tileItem.name}</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

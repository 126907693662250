import { Button } from "@mui/material";
import { Link } from "react-router-dom";

interface IProps {
  handleConversion: () => void;
}

export default function EditorJsMigratorComponent({
  handleConversion,
}: IProps) {
  return (
    <div className="divCenter divColumn divAlignItemsOnly my-80">
      <Link to="/home" className="hyperlink-green mx-5">
        Back
      </Link>
      <h3 className="errorMsg">
        {" "}
        Sorry! The article can no longer be edited and may require conversion.
      </h3>
      <h3 className="my-40">
        Don't worry - If this article is already published in the web, it will
        not affect public viewers.
      </h3>
      <strong>
        One of the reasons could be if this article was drafted using our legacy
        editor.
      </strong>
      <div className="my-20" style={{ width: "40%" }}>
        <p>
          During Feb 2024, we integrated a new editor in Blogs by Expensehut
          that enables users to author articles more quickly and without any
          usage of html. The legacy article needs a manual conversion.
        </p>
      </div>

      <div>
        <Button onClick={handleConversion}>Convert</Button>
      </div>
    </div>
  );
}

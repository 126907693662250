import * as AppConfigService from "./AppStorageService";

interface ExecuteServiceParams {
  method?: string;
  payload?: any;
  respCode?: number;
  respType?: "json" | "text" | "restrict";
  authenticated?: boolean; // true will use bearer token
  additionalHeaders?: any;
}

export async function executeService2(
  url: string,
  params?: ExecuteServiceParams,
) {
  const {
    authenticated = true,
    additionalHeaders,
    method = "GET",
    payload,
    respCode = 200,
    respType = "json",
  } = params || {};
  try {
    const defaultHeaders = {
      "Content-Type": "application/json",
    };

    let headers = {
      ...defaultHeaders,
      ...additionalHeaders,
    };

    if (authenticated) {
      const authHeaders = getAuthenticatedHeaders();
      headers = Object.assign({}, headers, authHeaders);
    }

    let options = {
      method,
      headers,
    };

    if (payload && (method === "POST" || method === "PUT")) {
      options = Object.assign({}, options, { body: JSON.stringify(payload) });
    }
    const response = await fetch(url, options);
    return processResponse(response, respCode, respType, method);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function processResponse(
  response: any,
  respCode: number,
  respType: string,
  methodType: string,
) {
  const code = response.status;
  if (
    respCode &&
    ((!Array.isArray(respCode) && code !== respCode) ||
      (Array.isArray(respCode) && !respCode.includes(code)))
  ) {
    let message = `Error executing ${methodType}`;
    if (respType === "json") {
      const errorBody = await response.json();
      message = errorBody.message;
    } else {
      const errorBodyAsText = await response.text();
      message = errorBodyAsText;
    }
    // eslint-disable-next-line no-throw-literal
    throw { status: code, message };
  }
  if (respCode !== 204 && respCode !== 201) {
    if (respType !== "restrict") {
      if (respType === "text") {
        // console.log('Returning response as Text');
        return response.text();
      }
      const json = response.json();
      // console.log(JSON.stringify(json));
      return json;
    }
  }
}

export async function uploadService(
  url: string,
  formData: FormData,
  methodType = "POST",
  respCode = 201,
  validateResponse = false,
) {
  try {
    const headers = getAuthenticatedHeaders();

    let options = {
      method: methodType,
      headers,
      body: formData,
    };
    const response = await fetch(url, options);
    return processResponse(
      response,
      respCode,
      validateResponse ? "json" : "restrict",
      methodType,
    );
  } catch (error: any) {
    throw error;
  }
}

function getAuthenticatedHeaders() {
  return {
    bearer: AppConfigService.getSignature() ?? "",
    "access-type": "offline",
  };
}

import { Link } from "react-router-dom";

export default function EditArticleNotFoundComponent() {
  return (
    <div className="divCenter divAlignItemsOnly" style={{ height: 200 }}>
      <h3> Sorry! The article you are looking for does not exist. Please go</h3>
      <Link to="/home" className="hyperlink-green mx-5">
        back
      </Link>
      <h3>and try again.</h3>
    </div>
  );
}

import React from "react";
import Alert from "@mui/material/Alert";

export interface IStatusMsg {
  type?: string;
  text?: string;
}

interface IProps {
  message: IStatusMsg | null | undefined;
  minHeight?: string;
  style?: any;
}

interface IState {
  statusMsg?: IStatusMsg | null;
}
export default class StatusMessageComponent extends React.Component<
  IProps,
  IState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      statusMsg: undefined,
    };
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.message !== prevProps.message) {
      this.setState({ statusMsg: this.props.message }, () => {
        setTimeout(() => this.setState({ statusMsg: undefined }), 2000);
      });
    }
  }

  render() {
    return (
      <div
        style={{
          minHeight: this.props.minHeight ?? "50px",
          ...this.props.style,
        }}
      >
        {this.state.statusMsg && (
          <Alert
            severity={this.state.statusMsg.type as any}
            onClose={() => this.setState({ statusMsg: undefined })}
          >
            {this.state.statusMsg.text}
          </Alert>
        )}
      </div>
    );
  }
}

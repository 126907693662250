import { Breadcrumbs } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import React from "react";
import { connect } from "react-redux";
import { getSubscription } from "../../actions/commonAction";
import { getFolders } from "../../actions/foldersAction";
import { deleteAsset, getAllAssets } from "../../services/AssetsService";
import {
  deleteFolder,
  getContents,
  getFolderById,
} from "../../services/FoldersService";
import { getFullPaths } from "../../services/PathsService";

import store from "../../store";
import {
  ICurrentItem,
  IFolder,
  IFolderData,
  ISubscription,
  IUser,
} from "../../types/appTypes";
import DeleteConfirmationDrawerComponent from "../shared/DeleteConfirmationDrawerComponent";
import InfoComponent from "../shared/InfoComponent";
import StatusMessageComponent, {
  IStatusMsg,
} from "../shared/StatusMessageComponent";
import AssetFullViewComponent2 from "./AssetFullViewComponent2";
import AssetUploadComponent from "./AssetUploadComponent";
import AssetsToolBar from "./AssetsToolBar";
import CreateFolderComponent from "./CreateFolderComponent";
import TileComponent from "./TileComponent";
import WhereUsedInLineComponent from "./WhereUsedInLineComponent";

interface IStateProps {
  limit: ISubscription;
  user: IUser;
  folders: IFolder[];
}

type IProps = IStateProps;

type ModeOptions = "create-folder" | "upload-file" | "home" | "rename-folder";

interface IState {
  statusMsg: IStatusMsg | undefined | null;
  folderData: IFolderData[];
  mode: ModeOptions;
  currentItem?: ICurrentItem;
  folderPaths: IFolder[];
  currentFolderName: string;
  assetsCount: number;
  deleteObject: ICurrentItem | undefined;
  whereUsedObject: ICurrentItem | undefined;
}

let DEFAULT_FOLDER_NAME = "Home";

class AssetsComponent2 extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      statusMsg: undefined,
      folderData: [],
      mode: "home",
      currentItem: undefined,
      folderPaths: [],
      currentFolderName: DEFAULT_FOLDER_NAME,
      assetsCount: 0,
      deleteObject: undefined,
      whereUsedObject: undefined,
    };
    this.deleteHandler = this.deleteHandler.bind(this);
    this.fetchFolderAndContents = this.fetchFolderAndContents.bind(this);
  }

  async fetchFolderAndContents() {
    let paths: IFolder[] = [];
    if (this.state.currentItem?.id) {
      paths = await getFullPaths(
        this.state.currentItem.id,
        this.state.currentItem.type,
      );

      if (this.state.currentItem.type === "folder") {
        const folder = await getFolderById(this.state.currentItem.id);
        this.setState({ currentFolderName: folder.name });
      } else {
        this.setState({ currentFolderName: DEFAULT_FOLDER_NAME });
      }
    } else {
      this.setState({ currentFolderName: DEFAULT_FOLDER_NAME });
    }
    this.setState({
      folderPaths: [
        {
          name: DEFAULT_FOLDER_NAME,
          _id: undefined! as any,
          createdDate: undefined! as any,
          username: undefined! as any,
        },
      ].concat(paths),
    });
    if (
      !this.state.currentItem?.id ||
      this.state.currentItem?.type === "folder"
    ) {
      const folderData = await getContents(
        this.state.currentItem?.id || "root",
      );
      this.setState({ folderData });
    }
    const assets = await getAllAssets(); // to keep track of remaining assets count
    this.setState({ assetsCount: assets.length });
  }

  async componentDidMount() {
    store.dispatch(getSubscription({ username: this.props.user.username }));
    store.dispatch(getFolders());
    try {
      await this.fetchFolderAndContents();
    } catch (err) {
      console.error(err);
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! There was a problem fetching data. Please try again.",
        },
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (
      this.state.currentItem !== prevState.currentItem ||
      this.props.folders !== prevProps.folders ||
      (!this.state.deleteObject &&
        this.state.deleteObject !== prevState.deleteObject)
    ) {
      this.setState({ mode: "home" });
      this.fetchFolderAndContents();
    }
  }

  async deleteHandler() {
    try {
      if (this.state.deleteObject?.type === "folder") {
        const folder = await getFolderById(this.state.deleteObject!.id!);
        const parentFolderId = folder.folderId;
        await deleteFolder(folder._id);
        store.dispatch(getFolders());
        // set current item to the parent folder id
        this.setState({ currentItem: { id: parentFolderId, type: "folder" } });
      } else {
        // mode is delete-file
        await deleteAsset(this.state.deleteObject!.id!);
        // this is bad impl but there is no other way to trigger find assets after delete
      }
      this.setState({
        statusMsg: {
          type: "success",
          text: "Deleted successfully!",
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({
        statusMsg: {
          type: "error",
          text: "Error on deletion. Please try again.",
        },
      });
    }
    this.setState({ deleteObject: undefined });
  }

  render() {
    if (
      this.state.currentItem?.type &&
      this.state.currentItem.type !== "folder"
    ) {
      return (
        <AssetFullViewComponent2
          id={this.state.currentItem?.id!}
          onBack={() =>
            this.setState({
              currentItem: {
                id: this.state.currentItem?.folderId,
                type: "folder" /* it should go to parent folder which is always folder */,
              },
            })
          }
        />
      );
    }

    const colwidth = Math.floor(window.innerWidth / 300);

    return (
      <div className="app-body-no-vertical" id="assets-component">
        <StatusMessageComponent message={this.state.statusMsg} />

        <DeleteConfirmationDrawerComponent
          title={`Are you sure you want to delete this ${this.state.deleteObject?.type === "image" ? "file" : "folder"}?`}
          subtitle={
            this.state.deleteObject?.type === "image"
              ? "This will also remove all assets inside this folder."
              : ""
          }
          open={!!this.state.deleteObject}
          onCancel={() => this.setState({ deleteObject: undefined })}
          onDeleteHandler={() => this.deleteHandler()}
        />

        <WhereUsedInLineComponent
          whereUsed={this.state.whereUsedObject}
          onClose={() => this.setState({ whereUsedObject: undefined })}
        />

        <div className="title">Assets Browser</div>

        <div className="divSpread" style={{ margin: "10px 0px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <span>Folder: </span>
            {
              // this is the full path until root folder
              this.state.folderPaths.map((folder: IFolder, i: number) => {
                return (
                  <span
                    className="hyperlink-green"
                    key={i}
                    onClick={() =>
                      this.setState({
                        currentItem: { id: folder._id, type: "folder" },
                      })
                    }
                  >
                    {folder.name}
                  </span>
                );
              })
            }
          </Breadcrumbs>

          <AssetsToolBar
            onCreateFolderCick={() =>
              this.setState({
                mode:
                  this.state.mode !== "create-folder"
                    ? "create-folder"
                    : "home",
              })
            }
            onUploadAssetClick={() =>
              this.setState({
                mode:
                  this.state.mode !== "upload-file" ? "upload-file" : "home",
              })
            }
            isRootFolder={!this.state.currentItem?.id}
            onDeleteFolderClick={() =>
              this.setState({ deleteObject: this.state.currentItem })
            }
            onRenameFolderClick={() => this.setState({ mode: "rename-folder" })}
          />
        </div>

        {this.state.mode === "upload-file" && (
          <AssetUploadComponent
            user={this.props.user}
            folderId={this.state.currentItem?.id}
            onUploadSuccess={() => {
              this.setState({ mode: "home" });
              this.fetchFolderAndContents();
            }}
          />
        )}

        {this.state.mode === "create-folder" && (
          <div className="divCenter">
            <CreateFolderComponent
              placeholder={"Enter folder name..."}
              mode={"create"}
              onSuccess={() => {
                this.setState({
                  mode: "home",
                  statusMsg: {
                    type: "success",
                    text: "Folder created succesfully",
                  },
                });
                store.dispatch(getFolders());
              }}
              onError={(message: string) =>
                this.setState({ statusMsg: { type: "error", text: message } })
              }
              onCancel={() => this.setState({ mode: "home" })}
              folderId={this.state.currentItem?.id}
            />
          </div>
        )}

        {(this.state.mode === "home" ||
          this.state.mode === "rename-folder") && (
          <div style={{ margin: "0%" }}>
            <div className="divSpread" id="folder-title">
              <h3>{this.state.currentFolderName}</h3>

              <div className="divCenterAlign">
                <span id="limits-count">
                  {" "}
                  <strong style={{ margin: "0px 5px" }}>
                    {this.props.limit?.assets
                      ? this.props.limit?.assets - this.state.assetsCount
                      : 0}
                  </strong>
                  assets remaining.{" "}
                </span>
                <InfoComponent tooltip="The number of assets allowed in your subscription." />
              </div>
            </div>

            {this.state.mode === "rename-folder" && (
              <div className="divCenter">
                <CreateFolderComponent
                  placeholder={"Enter new name for folder..."}
                  mode={"rename"}
                  onSuccess={() => {
                    this.setState({
                      mode: "home",
                      statusMsg: {
                        type: "success",
                        text: "Folder renamed succesfully",
                      },
                    });

                    store.dispatch(getFolders());
                  }}
                  onError={(message: string) =>
                    this.setState({
                      statusMsg: { type: "error", text: message },
                    })
                  }
                  onCancel={() => this.setState({ mode: "home" })}
                  folderId={this.state.currentItem?.id}
                />
              </div>
            )}

            <hr />

            <ImageList
              sx={{ minHeight: 200, margin: "2% 0" }}
              cols={colwidth}
              rowHeight={"auto"}
              id="assets-list-div"
            >
              <TileComponent
                contextMenu
                folderData={this.state.folderData}
                onSelect={(data) =>
                  this.setState({
                    currentItem: {
                      id: data.id,
                      type: data.type,
                      folderId: data.folderId,
                    },
                  })
                }
                onDelete={(data) => this.setState({ deleteObject: data })}
                onWhereUsed={(data) => this.setState({ whereUsedObject: data })}
              />
            </ImageList>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any): IStateProps {
  const { limit, user, folders } = state.app ?? {};
  return { limit, user, folders };
}

export default connect(mapStateToProps)(AssetsComponent2);
